import { CONSTANTS } from "./constants";
import attMap from '../configuration/attMap.json';
import detailMap from '../configuration/detailMap.json';

const initFormInfos = [{
  name: 'default',
  layout: detailMap,
  map: attMap
}];

export default (state = initFormInfos, action) => {
  switch (action.type) {
    case CONSTANTS.SET_FORM_INFOS:
      return action.value;
    default:
      return state;
  }
}
