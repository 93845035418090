
import React, { useEffect } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

const SUPPORTED_LANGUAGES = ['en', 'zh-TW', 'zh-CN'];
const SUPPORTED_ROLE = [
  { label_id: 'member', value: ''},
  { label_id: 'manager', value: 'manager'},
  { label_id: 'admin', value: 'admin'}
];

const EditDialog = (props) => {
  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [compony, setCompony] = React.useState('');
  const [organization, setOrganization] = React.useState('');
  const [role, setRole] = React.useState('');
  const [language, setLanguage] = React.useState('');


  useEffect(() => {
    setName(props.userInfo.name);
    setEmail(props.userInfo.email);
    setPhone(props.userInfo.phone);
    setCompony(props.userInfo.compony);
    setOrganization(props.userInfo.organization);
    setRole(props.userInfo.role);
    setLanguage(props.userInfo.language);
  }, [props.open]); // Refresh when open and close time.

  const handleOnClose = (event) => {
    props.onClose(event);
  };

  const handleOnSave = (event) => {
    const newInfo = {
      name, email, phone, compony, organization, role, language
    };
    props.onSave(newInfo);
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="edit-dialog-title"
      aria-describedby="edit-dialog-description"
    >
      <DialogTitle id="edit-dialog-title">
        <FormattedMessage id='edit'/>
      </DialogTitle>

      <DialogContent>
        <TextField
          fullWidth
          label={props.intl.formatMessage({ id: 'name' })}
          value={name}
          onChange={(event) => setName(event.target.value)}
        />
        <TextField
          fullWidth
          label={props.intl.formatMessage({ id: 'email' })}
          value={email}
          onChange={(event) => setEmail(event.target.value)}
        />
        <TextField
          fullWidth
          label={props.intl.formatMessage({ id: 'phone' })}
          value={phone}
          onChange={(event) => setPhone(event.target.value)}
        />
        <TextField
          fullWidth
          label={props.intl.formatMessage({ id: 'compony' })}
          value={compony}
          onChange={(event) => setCompony(event.target.value)}
        />
        <TextField
          fullWidth
          label={props.intl.formatMessage({ id: 'organization' })}
          value={organization}
          onChange={(event) => setOrganization(event.target.value)}
        />
        <FormControl fullWidth disabled={role === 'admin'}>
          <InputLabel id="role-select-label" shrink={true}><FormattedMessage id="role"/></InputLabel>
          <Select
            labelId="role-select-label"
            style={{ width: '100%'}}
            id='role-select'
            displayEmpty={true}
            label={props.intl.formatMessage({ id: 'role' })}
            value={role}
            onChange={(event) => setRole(event.target.value)}
          >
            {
              SUPPORTED_ROLE.map((sRole) => {
                return <MenuItem disabled={sRole.value === 'admin'} value={sRole.value}>
                  <FormattedMessage id={sRole.label_id}/>
                </MenuItem>;
              })
            }
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="language-select-label" shrink={true}><FormattedMessage id="language"/></InputLabel>
          <Select
            labelId="language-select-label"
            style={{ width: '100%'}}
            id='language-select'
            label={props.intl.formatMessage({ id: 'language' })}
            value={language}
            onChange={(event) => setLanguage(event.target.value)}
          >
            {SUPPORTED_LANGUAGES.map((sLang) => <MenuItem value={sLang}>{sLang}</MenuItem>)}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOnClose} color="primary">
          <FormattedMessage id='cancel'/>
        </Button>
        <Button onClick={handleOnSave} color="primary" autoFocus>
          <FormattedMessage id='save'/>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

EditDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  userInfo: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
};

export default injectIntl(EditDialog);

