import React, { Component, useState } from "react";
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { httpRequest } from '../../lib';

import EMAIL_TEMPLATE from '../../configuration/emailTemplate.json';

const rendorValue = (str, map) => {
  let myStr = str;
  Object.keys(map).forEach((key) => {
    if (key === 'agent_DR_Number' && map.case_status !== 'Approved') {
      myStr = myStr.replace(new RegExp(`{{${key}}}`, 'g'), 'N/A');
      return;
    }
    if (key === 'status_progress') {
      myStr = myStr.replace(new RegExp(`{{${key}}}`, 'g'), map[key].slice(0, map[key].indexOf('%') + 1) || '');
      return;
    }

    myStr = myStr.replace(new RegExp(`{{${key}}}`, 'g'), map[key] || '');
  });
  return myStr;
};

const getEmailRecivers = (ownerEmail, orgMember, receiverRoles) => {
  const ownerInfo = orgMember.find((om) => {
    return ownerEmail && om.email === ownerEmail;
  });

  return orgMember.filter((om) => {
    if (ownerEmail && om.email === ownerEmail && receiverRoles.includes('owner')) {
      return true;
    }
    const r1 = ownerInfo && ownerInfo.organization === om.organization;
    const r2 = receiverRoles.includes('manager') && om.role === 'manager';
    const r3 = receiverRoles.includes('admin') && om.role === 'admin';
    return (r1 && r2) || r3;
  }).map((om) => om.email);
};

const EmailDialog = (props) => {
  const [emailSubject, setEmailSubject] = useState('');
  const [emailContent, setEmailContent] = useState('');
  const [emailTemplate, setEmailTemplate] = useState('N/A');

  const [receiver, setReceiver] = useState([]);
  const [ccReceiver, setCcReceiver] = useState([]);
  const [bccReceiver, setBccReceiver] = useState([]);

  const onSubmitEmail = (event) => {
    props.onSubmitEmail({
      receiver,
      ccReceiver,
      bccReceiver,
      emailSubject,
      emailContent
    });
    props.onClose();
    setEmailSubject('');
    setEmailContent('');
    setEmailTemplate('N/A');
    setReceiver([]);
    setCcReceiver([]);
    setBccReceiver([]);
  };

  const onCloseEmailDialog = (event) => {
    props.onClose();
    setEmailSubject('');
    setEmailContent('');
    setEmailTemplate('N/A');
    setReceiver([]);
    setCcReceiver([]);
    setBccReceiver([]);
  };

  return (
    <Dialog
      maxWidth='md'
      open={props.open}
      onClose={onCloseEmailDialog}
      aria-labelledby="email-dialog-title"
      aria-describedby="email-dialog-description"
    >
      <DialogTitle style={{ minWidth: '600px'}} id="email-dialog-title">
        <FormattedMessage id="send_email_title" />
      </DialogTitle>
      <DialogContent>
        {
          /*
          <DialogContentText id="email-dialog-description">
            <FormattedMessage id="send_email_description" />
          </DialogContentText>
          */
        }
        <FormControl fullWidth>
          <label style={{ marginBottom: '-18px', fontSize: '12px' }}><FormattedMessage id='select_email_tmp'/></label>
          <Select
            style={{ width: '100%'}}
            id="email_temepalte_select"
            label={props.intl.formatMessage({ id: 'select_email_tmp' })}
            value={emailTemplate}
            onChange={(event) => {
              const item = EMAIL_TEMPLATE.find((tmp) => {
                return tmp.key === event.target.value;
              });
              setEmailTemplate(event.target.value);
              if (item) {
                const r = getEmailRecivers(props.ownerEmail, props.orgMember, item.receiver);
                const ccr = getEmailRecivers(props.ownerEmail, props.orgMember, item.ccReceiver);
                const bccr = getEmailRecivers(props.ownerEmail, props.orgMember, item.bccReceiver);

                setReceiver(r);;
                setCcReceiver(ccr);
                setBccReceiver(bccr);
                // TODO: redor form value to content and subject

                setEmailSubject(rendorValue(item.subject, props.form));
                setEmailContent(rendorValue(item.content, props.form));
              }
            }}
          >
            {
              EMAIL_TEMPLATE.map((item) => {
                return <MenuItem value={item.key}>{item.key}</MenuItem>
              })
            }
          </Select>
        </FormControl>
        <Autocomplete
          fullWidth
          multiple
          value={receiver}
          id="receiver-filled"
          onChange={(event, value) => setReceiver(value)}
          options={props.orgMember.map((om) => om.email)}
          freeSolo
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip variant="outlined" label={option} {...getTagProps({ index })}/>
            ))
          }
          renderInput={(params) => (
            <TextField {...params} label={props.intl.formatMessage({ id: 'receiver' })} />
          )}
        />
        <Autocomplete
          fullWidth
          multiple
          value={ccReceiver}
          id="ccReceiver-filled"
          onChange={(event, value) => setCcReceiver(value)}
          options={props.orgMember.map((om) => om.email)}
          freeSolo
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip variant="outlined" label={option} {...getTagProps({ index })}/>
            ))
          }
          renderInput={(params) => (
            <TextField {...params} label={props.intl.formatMessage({ id: 'ccReceiver' })} />
          )}
        />
        <Autocomplete
          fullWidth
          multiple
          value={bccReceiver}
          id="bccReceiver-filled"
          onChange={(event, value) => setBccReceiver(value)}
          options={props.orgMember.map((om) => om.email)}
          freeSolo
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip variant="outlined" label={option} {...getTagProps({ index })}/>
            ))
          }
          renderInput={(params) => (
            <TextField {...params} label={props.intl.formatMessage({ id: 'bccReceiver' })} />
          )}
        />
        <TextField
          fullWidth
          type='text'
          label={props.intl.formatMessage({ id: 'email_subject' })}
          value={emailSubject}
          multiline={false}
          onChange={(event) => {
            setEmailSubject(event.target.value);
          }}
        />
        <TextField
          fullWidth
          type='text'
          label={props.intl.formatMessage({ id: 'email_content' })}
          value={emailContent}
          multiline={true}
          onChange={(event) => {
            setEmailContent(event.target.value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseEmailDialog} variant="contained">
        <FormattedMessage id="cancel" />
        </Button>
        <Button onClick={onSubmitEmail} variant="contained" color="primary">
          <FormattedMessage id="send" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

EmailDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  orgMember: PropTypes.array.isRequired,
  ownerEmail: PropTypes.any.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmitEmail: PropTypes.func.isRequired,
  form: PropTypes.any.isRequired
};

export default injectIntl(EmailDialog);
