import React, { useState } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Tooltip from '@material-ui/core/Tooltip';
import { SearchFilter, PickSelect, MutiPickSelect, DateRangeFilter } from '../CustComponents';
import PropTypes from 'prop-types';
import { FILTER_CONSTANTS, optionRoleFilter } from './filterLib';

const {
  SEARCH_STATUS,
  UPDATE_TIME_RANGE,
  PRODUCT_TYPE,
  REGISTRATION_PROGRESS
} = FILTER_CONSTANTS;

const FilterComponents = (props) => {
  const [filterDialogOpen, setFilterDialogOpen] = useState(false);
  const filterChange = (key, value) => {

    // const filterCondition = {
    //   ...props.filterInfo,

    //   strictFilterValue, // string
    //   greedyFilterValue, // string
    //   statusFilterValue, // 多選, Array<string>
    //   updateRangeFilterValue, // 單選
    //   productTypeFilterValue,
    //   progressFilterValue, // 多選
    //   createStampFilterLeftValue, // 單選-左
    //   createStampFilterRightValue, // 單選-右
    //   caseApprovalDateFilterLeftValue, // 單選-左
    //   caseApprovalDateFilterRightValue, // 單選-右
    //   estimatedClosingFilterLeftValue, // 單選-左
    //   estimatedClosingFilterRightValue, // 單選-右
    //   ownerFilterValue, // 多選
    //   dataFormFilterValue // 多選
    // };
    // filterCondition[key] = value;
    props.onFilterInfoChange(key, value);
  };

  const onStrictFilterChange = (event) => {
    // setStrictFilterValue(event.target.value);
    filterChange('strictFilterValue', event.target.value);
  };
  
  const onGreedyFilterChange = (event) => {
    // setGreedyFilterValue(event.target.value);
    filterChange('greedyFilterValue', event.target.value);
  };

  const onStatusFilterChange = (event) => {
    // setStatusFilterValue(event.target.value);
    filterChange('statusFilterValue', event.target.value);
  };

  const onUpdateRangeFilterChange = (event) => {
    // setUpdateRangeFilterValue(event.target.value);
    filterChange('updateRangeFilterValue', event.target.value);
  };

  const onProductTypeFilterChange = (event) => {
    // setProductTypeFilterValue(event.target.value);
    filterChange('productTypeFilterValue', event.target.value);
  };

  const onProgressFilterChange = (event) => {
    // setProgressFilterValue(event.target.value);
    filterChange('progressFilterValue', event.target.value);
  };

  const onCreateStampFilterLeftChange = (date) => {
    // setCreateStampFilterLeftValue(date);
    filterChange('createStampFilterLeftValue', date);
  };

  const onCaseApprovalDateFilterLeftChange = (date) => {
    // setCaseApprovalDateFilterLeftValue(date);
    filterChange('caseApprovalDateFilterLeftValue', date);
  };

  const onCaseApprovalDateFilterRightChange = (date) => {
    // setCaseApprovalDateFilterRightValue(date);
    filterChange('caseApprovalDateFilterRightValue', date);
  };

  const onCreateStampFilterRightChange = (date) => {
    // setCreateStampFilterRightValue(date);
    filterChange('createStampFilterRightValue', date);
  };

  const onEstimatedClosingFilterLeftChange = (date) => {
    // setEstimatedClosingFilterLeftValue(date);
    filterChange('estimatedClosingFilterLeftValue', date);
  };

  const onEstimatedClosingFilterRightChange = (date) => {
    // setEstimatedClosingFilterRightValue(date);
    filterChange('estimatedClosingFilterRightValue', date);
  };

  const onOwnerFilterChange = (event) => {
    // setOwnerFilterValue(event.target.value);
    filterChange('ownerFilterValue', event.target.value);
  };

  const onDataFormFilterChange = (event) => {
    // setDataFormFilterValue(event.target.value);
    filterChange('dataFormFilterValue', event.target.value);
  };

  const onProductFilterChange = (event) => {
    // productFilterValue(event.target.value);
    filterChange('productFilterValue', event.target.value);
  };

  const onFilterDialogOpen = () => {
    setFilterDialogOpen(true);
  };

  const onFilterDialogClose = () => {
    setFilterDialogOpen(false);
  };

  const strictFilterValue = props.filterInfo.strictFilterValue || '';
  const greedyFilterValue = props.filterInfo.greedyFilterValue || '';
  const statusFilterValue = props.filterInfo.statusFilterValue || []; // 多選
  const updateRangeFilterValue = props.filterInfo.updateRangeFilterValue || ''; // 單選
  const productTypeFilterValue = props.filterInfo.productTypeFilterValue || [];
  const progressFilterValue = props.filterInfo.progressFilterValue || []; // 多選

  const caseApprovalDateFilterLeftValue = props.filterInfo.caseApprovalDateFilterLeftValue || null; // 單選-左
  const caseApprovalDateFilterRightValue = props.filterInfo.caseApprovalDateFilterRightValue || null; // 單選-左
  const createStampFilterLeftValue = props.filterInfo.createStampFilterLeftValue || null; // 單選-左
  const createStampFilterRightValue = props.filterInfo.createStampFilterRightValue || null; // 單選-右
  const estimatedClosingFilterLeftValue = props.filterInfo.estimatedClosingFilterLeftValue || null; // 單選-左
  const estimatedClosingFilterRightValue = props.filterInfo.estimatedClosingFilterRightValue || null; // 單選-右
  const ownerFilterValue = props.filterInfo.ownerFilterValue || []; // 多選
  const dataFormFilterValue = props.filterInfo.dataFormFilterValue || []; // 多選
  const productFilterValue = props.filterInfo.productFilterValue || []; // 多選

  const strictFilterComponent = (styleJson) => {
    return (
      <SearchFilter
        style={styleJson}
        title={props.intl.formatMessage({ id: 'search' })}
        placeholder={props.intl.formatMessage({ id: 'strict_search_msg' })}
        enable={props.enableStrictFilter}
        value={strictFilterValue}
        onChange={onStrictFilterChange}
      />
    );
  };

  const greedyFilterComponent = (styleJson) => {
    return (
      <SearchFilter
        style={styleJson}
        title={props.intl.formatMessage({ id: 'greedy_search' })}
        placeholder={props.intl.formatMessage({ id: 'greedy_search_msg' })}
        enable={props.enableGreedyFilter}
        value={greedyFilterValue}
        onChange={onGreedyFilterChange}
      />
    );
  };

  const statusFilterComponent = (styleJson) => {
    return (
      <MutiPickSelect
        style={styleJson}
        title={props.intl.formatMessage({ id: 'focus_state' })}
        enable={props.enableStatusFilter}
        value={statusFilterValue}
        options={optionRoleFilter(accountRole, SEARCH_STATUS)}
        onChange={onStatusFilterChange}
      />
    );
  };

  const updateRangeFilterComponent = (styleJson) => {
    return (
      <PickSelect
        style={styleJson}
        title={props.intl.formatMessage({ id: 'update_range' })}
        enable={props.enableUpdateRangeFilter}
        value={updateRangeFilterValue}
        options={optionRoleFilter(accountRole, UPDATE_TIME_RANGE)}
        onChange={onUpdateRangeFilterChange}
      />
    );
  };

  const productTypeFilterComponent = (styleJson) => {
    return (
      <MutiPickSelect
        style={styleJson}
        title={props.intl.formatMessage({ id: 'product_type' })}
        enable={props.enableProductTypeFilter}
        value={productTypeFilterValue}
        options={optionRoleFilter(accountRole, PRODUCT_TYPE)}
        onChange={onProductTypeFilterChange}
      />
    );
  };

  const progressFilterComponent = (styleJson) => {
    return (
      <MutiPickSelect
        style={styleJson}
        title={props.intl.formatMessage({ id: 'status_progress' })}
        enable={props.enableProgressFilter}
        value={progressFilterValue}
        options={optionRoleFilter(accountRole, REGISTRATION_PROGRESS)}
        onChange={onProgressFilterChange}
      />
    );
  };

  const sysCreateStampFilterComponent = (styleJson) => {
    return (
      <DateRangeFilter
        style={styleJson}
        title={props.intl.formatMessage({ id: 'sys_create_stamp' })}
        enable={props.enableCreateStampFilter}
        leftValue={createStampFilterLeftValue}
        rightValue={createStampFilterRightValue}
        onLeftChange={onCreateStampFilterLeftChange}
        onRightChange={onCreateStampFilterRightChange}
      />
    );
  };

  const caseApprovalDateFilterComponent = (styleJson) => {
    return (
      <DateRangeFilter
        style={styleJson}
        title={props.intl.formatMessage({ id: 'case_approval_date' })}
        enable={props.enableCaseApprovalDateFilter}
        leftValue={caseApprovalDateFilterLeftValue}
        rightValue={caseApprovalDateFilterRightValue}
        onLeftChange={onCaseApprovalDateFilterLeftChange}
        onRightChange={onCaseApprovalDateFilterRightChange}
      />
    );
  };

  const estimatedClosingFilterComponent = (styleJson) => {
    return (
      <DateRangeFilter
        style={styleJson}
        title={props.intl.formatMessage({ id: 'status_estimatedClosing' })}
        enable={props.enableEstimatedClosingFilter}
        leftValue={estimatedClosingFilterLeftValue}
        rightValue={estimatedClosingFilterRightValue}
        onLeftChange={onEstimatedClosingFilterLeftChange}
        onRightChange={onEstimatedClosingFilterRightChange}
      />
    );
  };

  const ownerFilterComponent = (styleJson) => {
    return (
      <MutiPickSelect
        style={styleJson}
        title={props.intl.formatMessage({ id: 'owner_filter' })}
        enable={props.enableOwnerFilter}
        value={ownerFilterValue}
        options={props.ownerList}
        onChange={onOwnerFilterChange}
      />
    );
  };

  const dataFormFilterComponent = (styleJson) => {
    return (
      <MutiPickSelect
        style={styleJson}
        title={props.intl.formatMessage({ id: 'data_form_filter' })}
        enable={props.enableDataFormFilter}
        value={dataFormFilterValue}
        options={props.dataFormList}
        onChange={onDataFormFilterChange}
      />
    );
  };

  const productFilterComponent = (styleJson) => {
    return (
      <MutiPickSelect
        style={styleJson}
        title={props.intl.formatMessage({ id: 'product_filter' })}
        enable={props.enableProductFilter}
        value={productFilterValue}
        options={props.productList}
        onChange={onProductFilterChange}
      />
    );
  };


  const accountRole = props.account ? props.account.role : 'owner';
  if (props.mode === 'accordion') {
    return (
      <div style={{ height: '69px' }}>
        <Accordion style={{ zIndex: 10, width: '340px' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1c-content"
            id="panel1c-header"
          >
            <div>
              <div style={{ margin: '13px 10px 13px 0px', float: 'left' }}>
                <Typography style={{ fontWeight: 500 }}>
                  <FormattedMessage id="search"/>
                </Typography>
              </div>
              <div style={{ float: 'left' }}>
                <div style={{ width: '100%' }}>
                  {/* Strict filter */}
                  {strictFilterComponent({ width: '100%' })}
                </div>
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails >
            <div>
              {/* Greedy filter */}
              {greedyFilterComponent({ minWidth: "310px", float: 'left' })}
              {/* Status filter */}
              {statusFilterComponent({ minWidth: "310px", float: 'left' })}
              {/* Update range filter */}
              {updateRangeFilterComponent({ minWidth: "310px", float: 'left' })}
              {/* Product type filter */}
              {productTypeFilterComponent({ minWidth: "310px", float: 'left' })}
              {/* Progress filter */}
              {progressFilterComponent({ minWidth: "310px", float: 'left' })}
              {/* sys create stamp filter */}
              {sysCreateStampFilterComponent({ minWidth: "310px", float: 'left' })}
              {/* Case Approval date filter */}
              {caseApprovalDateFilterComponent({ minWidth: "310px", float: 'left' })}
              {/* Estimated closing filter */}
              {estimatedClosingFilterComponent({ minWidth: "310px", float: 'left' })}
              {/* Owner filter */}
              {ownerFilterComponent({ minWidth: "310px", float: 'left' })}
              {/* Data form filter */}
              {dataFormFilterComponent({ minWidth: "310px", float: 'left' })}
              {/* Product filter */}
              {productFilterComponent({ minWidth: "310px", float: 'left' })}
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    );
  }
  if (props.mode === 'dialog') {
    return (
      <>
        <Tooltip title={props.intl.formatMessage({ id: 'search' })}>
          <IconButton onClick={onFilterDialogOpen}>
            <SearchIcon fontSize='large' color='primary'/>
          </IconButton>
        </Tooltip>
        <Dialog open={filterDialogOpen} onClose={() => {}} fullScreen>
          <DialogTitle style={{ fontSize: '28px' }}>
            {props.intl.formatMessage({ id: 'search' })}
          </DialogTitle>
          <DialogContent>
            <div>
              {/* Strict filter */}
              {strictFilterComponent({ minWidth: "100%" })}
              {/* Greedy filter */}
              {greedyFilterComponent({ minWidth: "100%" })}
              {/* Status filter */}
              {statusFilterComponent({ minWidth: "100%" })}
              {/* Update range filter */}
              {updateRangeFilterComponent({ minWidth: "100%" })}
              {/* Product type filter */}
              {productTypeFilterComponent({ minWidth: "100%" })}
              {/* Progress filter */}
              {progressFilterComponent({ minWidth: "100%" })}
              {/* sys create stamp filter */}
              {sysCreateStampFilterComponent({ minWidth: "100%" })}
              {/* Case Approval date filter */}
              {caseApprovalDateFilterComponent({ minWidth: "100%" })}
              {/* Estimated closing filter */}
              {estimatedClosingFilterComponent({ minWidth: "100%" })}
              {/* Owner filter */}
              {ownerFilterComponent({ minWidth: "100%" })}
              {/* Data form filter */}
              {dataFormFilterComponent({ minWidth: "100%" })}
              {/* Product filter */}
              {productFilterComponent({ minWidth: "100%" })}
            </div>
            <div style={{ textAlign: 'center', marginTop: '15px' }}>
              <Button style={{ width: '100%' }} variant='contained' onClick={onFilterDialogClose}>
                <FormattedMessage id='close' />
              </Button>
            </div>
          </DialogContent>
        </Dialog>

      </>
    );
  }
  return (
    <div style={{ minWidth: "calc(100vw - 500px)" }}>
      {/* Status filter */}
      {statusFilterComponent({ minWidth: "250px", float: 'left', margin: '0px 10px' })}
      {/* Update range filter */}
      {updateRangeFilterComponent({ minWidth: '100px', float: 'left', margin: '0px 10px' })}
      {/* Product type filter */}
      {productTypeFilterComponent({ minWidth: '230px', float: 'left', height: '61px', margin: '0px 10px' })}
      {/* Progress filter */}
      {progressFilterComponent({ minWidth: '290px', float: 'left', height: '61px', margin: '0px 10px' })}
      {/* sys create stamp filter */}
      {sysCreateStampFilterComponent({ minWidth: '270px', float: 'left', height: '61px', margin: '0px 10px' })}
      {/* Estimated closing filter */}
      {estimatedClosingFilterComponent({ minWidth: '270px', float: 'left', height: '61px', margin: '0px 10px' })}
      {/* Strict filter */}
      <div style={{ float: 'left' }}>
        {strictFilterComponent({ width: '100%' })}
      </div>
      {/* Greedy filter */}
      <div style={{ float: 'left' }}>
        {greedyFilterComponent({ minWidth: "310px", float: 'left' })}
      </div>
      {/* Owner filter */}
      {ownerFilterComponent({ minWidth: "250px", float: 'left', margin: '0px 10px' })}
      {/* Data form filter */}
      {dataFormFilterComponent({ minWidth: "250px", float: 'left', margin: '0px 10px' })}
      {/* Product filter */}
      {productFilterComponent({ minWidth: "250px", float: 'left', margin: '0px 10px' })}
    </div>
  );
};

FilterComponents.propTypes = {
  account: PropTypes.object.isRequired,
  mode: PropTypes.string,
  ownerList: PropTypes.array,
  dataFormList: PropTypes.array,
  productList: PropTypes.array,
  filterInfo: PropTypes.object.isRequired,
  onFilterInfoChange: PropTypes.func,
  enableStrictFilter: PropTypes.bool,
  enableGreedyFilter: PropTypes.bool,
  enableStatusFilter: PropTypes.bool,
  enableUpdateRangeFilter: PropTypes.bool,
  enableProductTypeFilter: PropTypes.bool,
  enableProgressFilter: PropTypes.bool,
  enableCreateStampFilter: PropTypes.bool,
  enableEstimatedClosingFilter: PropTypes.bool,
  enableOwnerFilter: PropTypes.bool,
  enableDataFormFilter: PropTypes.bool,
  enableProductFilter: PropTypes.bool
};

export default injectIntl(FilterComponents);
