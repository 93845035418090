import React, { useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { connect } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import { setAuthInfo } from '../../actions'
import LoginOutButton from './LoginOutButton';
import envConfig from '../../configuration/env.json';
// import 'react-confirm-alert/src/react-confirm-alert.css';
import '../../assets/scss/cust-react-confirm-alert.scss';

const NEED_VERIFY_MESSAGE = "Please verify your email before logging in.";

const SignAuth0 = (props) => {
  const { user, logout, error, isAuthenticated, getAccessTokenSilently} = useAuth0();

  const findGetParameter = (parameterName) => {
    let result = null,
      tmp = [];
    const items = window.location.search.substr(1).split('&');
    for (let index = 0; index < items.length; index++) {
      tmp = items[index].split('=');
      if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
    }
    return result;
  }

  useEffect(() => {
    if (isAuthenticated) {
      getAccessTokenSilently({
        audience: envConfig.audience,
        scope: envConfig.scope
      }).then((accessToken) => {
        props.setAuthInfo({ ...user, accessToken });
      }).catch((err) => {
        // console.log(err);
      });
    } else if (findGetParameter('error') === 'unauthorized') {
      // console.log(findGetParameter('error_description'));
      confirmAlert({
        title: 'Warning',
        message: findGetParameter('error_description'),
        buttons: [
          { label: 'Yes', onClick: () => logout() }
        ]
      });
    }
  }, [isAuthenticated, error]);

  return (
    <div>
      <LoginOutButton/>
      {isAuthenticated && (
        <div>
          <img src={user.picture} alt={user.name} />
          <h2>{user.name}</h2>
          <p>{user.email}</p>
        </div>
      )}
    </div>
  );
};

export default connect(null, { setAuthInfo })(SignAuth0);
