import React, { useState, useEffect } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import XLSX from 'xlsx';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Tooltip from '@material-ui/core/Tooltip';
import Select from "@material-ui/core/Select";
import MenuItem from '@material-ui/core/MenuItem';

import NoteAddIcon from '@material-ui/icons/NoteAdd';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

import { xlsxDownload_ws } from '../ExcelExportButton/fileEngine';
import XlsxTable from './XlsxTable';

const useStyles = makeStyles(() => ({
  footerButton: {
    width: '120px',
    float: 'right',
    margin: '0px 5px'
  }
}));

const getTitleLineIndex = (data) => {
  for (let i = 0; i < data.length; i += 1 ) {
    // Property fields will not be empty
    const line = data[i];
    const fillLine = line.filter((cellValue) => {
      return cellValue;
    });
    const isMatch = fillLine.every((cellValue, index) => {
      return cellValue === line[index];
    });
    // Allow tail cell empty less than 5
    if (isMatch && fillLine.length + 5 >= line.length) {
      return i;
    }
  }
  return 0
};

const getLastLineIndex = (startIndex, data) => {
  for (let i = startIndex; i < data.length; i += 1 ) {
    const line = data[i];
    const lineLength = line.length;
    const myLine = line.filter((myValue) => {
      return myValue;
    });
    if ((line.length / 3) > myLine.length) {
      return i;
    }
  }
  return data.length;
};

const initXlsxTableTitle = (props, formInfos, formInfoIndex) => {
  const titles = formInfos[formInfoIndex].map.filter((titleOjb) => {
    return titleOjb.allowEditStage.includes('Waiting');
  }).map((titleOjb) => {
    // TODO: i18m in here
    return { value: props.intl.formatMessage({ id: titleOjb.title_id }), readOnly: true }
  });
  return titles;
};

const initXlsxTable = (props, formInfos, formInfoIndex) => {
  const xlsxTitles = initXlsxTableTitle(props, formInfos, formInfoIndex);
  const initXlsxTable = [...new Array(5)].map((el, index) => {
    const emptycell = xlsxTitles.map(() => { return { value: '', readOnly: false }; });
    return [{ value: `${index + 1}`, readOnly: true }, ...emptycell];
  });
  const gridData = [
    [{ value: '', readOnly: true }, ...xlsxTitles],
    ...initXlsxTable
  ];
  return gridData;
};

const xml2Table = (props, formInfos, formInfoIndex, data) => {
  const xlsxTitles = initXlsxTableTitle(props, formInfos, formInfoIndex);
  const rows = data.map((cols, index) => {
    const cells = cols.map((value, index3) => {
      return { value: `${value}`, readOnly: false };
    }).splice(0, xlsxTitles.length);

    const emptyLength = (xlsxTitles.length - cells.length > 0) ? xlsxTitles.length - cells.length : 0;
    const emptycells = [...new Array(xlsxTitles.length - cells.length)].map(() => {
      return { value: '', readOnly: false };
    });
    return [{ value: `${index + 1}`, readOnly: true }, ...cells, ...emptycells];
  });
  return [
    [{ value: '', readOnly: true }, ...xlsxTitles],
    ...rows
  ];
};

const ImportDialog = (props) => {
  const classes = useStyles();
  const { onClose, open, onSubmit, onValidate, formInfos } = props;
  const [dataForm, setDataForm] = useState(formInfos[0].name);
  const [formInfoIndex, setFormInfoIndex] = useState(0);

  // ====== i18n title end ======
  const [ xlsxFileName, setXlsxFileName] = useState('');
  const [ xlsSheetName, setXlsSheetName] = useState('');
  const [grid, setGrid] = useState(initXlsxTable(props, props.formInfos, formInfoIndex));

  useEffect(() => {
    {/* Single form using default dataForm */}
    setFormInfoIndex(0);
    setDataForm(formInfos[0].name);
    setGrid(initXlsxTable(props, props.formInfos, formInfoIndex));
  }, [formInfos]);

  const closeDialog = () => {
    onClose();
    setGrid(initXlsxTable(props, props.formInfos, formInfoIndex));
    setXlsxFileName('');
    setXlsSheetName('');
  };

  const readXLSXFile = (event) => {
    if (event.target.files.length === 0) {
      return;
    }
    const fileNmae = event.target.files[0].name;
    const file = event.target.files[0];

    /* write a workbook */
    /* Boilerplate to set up FileReader */
		const reader = new FileReader();
		const rABS = !!reader.readAsBinaryString;
		reader.onload = (e) => {
			/* Parse data */
			const bstr = e.target.result;
			const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' });
			/* Get first worksheet */
			const wsname = wb.SheetNames[0];
			const ws = wb.Sheets[wsname]; // title
			/* Convert array of arrays */
			const data = XLSX.utils.sheet_to_json(ws, {
        header: 1,
        defval: '',
        blankrows: false,
        raw: false // false: format string
      }); // value
			/* Update state */
      setXlsxFileName(fileNmae);
      setXlsSheetName(wsname);
      // Filter noisy data
      const titleLineIndex = getTitleLineIndex(data);
      const lastLineIndex = getLastLineIndex(titleLineIndex, data);
      setGrid(xml2Table(props, props.formInfos, formInfoIndex, data.slice(titleLineIndex + 1, lastLineIndex)));
		};
		if (rABS) {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsArrayBuffer(file);
    }
  };

  const uploadData = () => {
    // TODO: validate data in here
    const isValidated = (typeof onValidate === 'function') ? onValidate(dataForm, grid) : true;

    if (isValidated) {
      onSubmit(dataForm, grid);
      closeDialog();
    }
  };

  const downloadTemplateFile = () => {
    const headers = props.formInfos[formInfoIndex].map.filter((attInfo) => {
      return attInfo.allowEditStage.includes('Waiting');
    });
    const maxLookupLength = headers.reduce((preValue, currAttInfo) => {
      if (currAttInfo.lookup && currAttInfo.lookup.length > preValue) {
        return currAttInfo.lookup.length;
      }
      return preValue;
    }, 0);
    const templateFileBody = [];
    for (let i = 0; i < maxLookupLength; i += 1) {
      const json = {};
      headers.forEach((attInfo, attIndex) => {
        if (i === 0) {
          // attribute
          json[`${attInfo.type}_${attInfo.key}`] = props.intl.formatMessage({ id: attInfo.title_id });
        } else if (attInfo.lookup && attInfo.lookup.length > i) {
          // lookup value
          json[`${attInfo.type}_${attInfo.key}`] = attInfo.lookup[i].value;
        } else {
          // fill empty
          json[`${attInfo.type}_${attInfo.key}`] = '';
        }
      });
      templateFileBody.push(json);
    }

    const ws = XLSX.utils.json_to_sheet(templateFileBody, { skipHeader: true });
    const tempFileName = props.intl.formatMessage({ id: 'template_file_name' });
    xlsxDownload_ws(ws, `${tempFileName}.xlsx`, 'Sheet1');
  };

  return (
    <Dialog open={open} onClose={closeDialog} maxWidth={true}>
      <DialogTitle id='simple-dialog-title'>
        <Typography variant='h3' style={{ float: 'left' }}>
          <FormattedMessage id={'import_title'}/>
        </Typography>
        <Tooltip title={props.intl.formatMessage({ id: 'pick_file' })}>
          <IconButton variant='contained' component='label' style={{ float: 'right', padding: '4px' }}>
            {/*<FormattedMessage id={'uploadFile'} values={{ type: 'xlsx' }}></FormattedMessage>*/}
            <NoteAddIcon/>
            <input type='file' hidden accept=".xlsx" onChange={readXLSXFile} />
          </IconButton>
        </Tooltip>
        <Tooltip title={props.intl.formatMessage({ id: 'download_template' })}>
          <IconButton
            variant='contained'
            component='label'
            style={{ float: 'right', padding: '4px' }}
            onClick={downloadTemplateFile}
          >
            <CloudDownloadIcon/>
          </IconButton>
        </Tooltip>

        {/* Single form using default dataForm */}
        {/* <div style={{ float: 'right', padding: '4px' }}>
          <FormattedMessage id={'select_data_form'}/>
          <Select
            style={{ width: '100%'}}
            id="select_data_form"
            value={dataForm}
            onChange={(event) => {
              const fIndex = formInfos.findIndex((formInfo) => {
                return formInfo.name === event.target.value;
              });
              setDataForm(event.target.value);
              setFormInfoIndex(fIndex);
              setGrid(initXlsxTable(props, props.formInfos, fIndex));
            }}
            label={props.intl.formatMessage({ id: 'select_data_form' })}
          >
            {
              formInfos.map((item) => {
                return <MenuItem value={item.name}>{item.name}</MenuItem>
              })
            }
          </Select>
        </div> */}

        {/*<Button variant='outlined' color='primary' onClick={() => console.log('download event')} style={{ float: 'right' }}>
          <FormattedMessage id={'downloadTemplateFile'}></FormattedMessage>
        </Button>*/}
      </DialogTitle>

      <DialogContent dividers>
        {xlsxFileName &&
          <Typography>
            {xlsSheetName}({xlsxFileName})
          </Typography>
        }
        <XlsxTable grid={grid} setGrid={setGrid} />
      </DialogContent>
      <div style={{ margin: '15px 30px' }}>
        <Button variant='contained' color='primary' onClick={uploadData} className={classes.footerButton}>
          <FormattedMessage id={'add'}></FormattedMessage>
        </Button>
        <Button variant='outlined' color='primary' onClick={closeDialog} className={classes.footerButton}>
          <FormattedMessage id={'back'}></FormattedMessage>
        </Button>
      </div>
    </Dialog>
  );
};

const ExcelReaderButton = (props) => {
  const { onSubmit, formInfos, onValidate } = props;
  const [dialogOpen, setDialogOpen] = React.useState(false);

  return (
    <div>
      <Tooltip title={props.intl.formatMessage({ id: 'import' })}>
        <IconButton aria-label="Import" onClick={() => setDialogOpen(true)}>
          <AddCircleIcon fontSize="large" color="primary"/>
        </IconButton>
      </Tooltip>
      <ImportDialog
        onSubmit={onSubmit}
        formInfos={formInfos}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onValidate={onValidate}
        {...props}
      />
    </div>
  );
};

export default injectIntl(ExcelReaderButton);