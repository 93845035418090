const SEARCH_STATUS = [
  { label: 'Waiting', value: 'Waiting', roles: ['owner', 'manager', 'admin'] },
  { label: 'Approved', value: 'Approved', roles: ['owner', 'manager', 'admin'] },
  { label: 'Open', value: 'Open', roles: ['owner', 'manager', 'admin'] },
  { label: 'Reject', value: 'Reject', roles: ['owner', 'manager', 'admin'] },
  { label: 'Expired', value: 'Expired', roles: ['owner', 'manager', 'admin'] },
  { label: 'Archive', value: 'Archive', roles: ['admin'] }
];

const UPDATE_TIME_RANGE = [
  { label: 'All', value: 0, range1: 0, range2: 9999, roles: ['owner', 'manager', 'admin'] },
  { label: '0 ~ 15', value: 1, range1: 0, range2: 15, roles: ['owner', 'manager', 'admin'] },
  { label: '0 ~ 30', value: 2, range1: 0, range2: 30, roles: ['owner', 'manager', 'admin'] },
  { label: '15 ~ 30', value: 3, range1: 15, range2: 30, roles: ['owner', 'manager', 'admin'] },
  { label: '15 ~ ∞', value: 4, range1: 15, range2: 9999, roles: ['owner', 'manager', 'admin'] },
  { label: '30 ~ ∞', value: 5, range1: 30, range2: 9999, roles: ['owner', 'manager', 'admin'] },
];

const PRODUCT_TYPE = [
  { label: 'New Business', value: 'New Business', roles: ['owner', 'manager', 'admin'] },
  { label: 'Existing Business', value: 'Existing Business', roles: ['owner', 'manager', 'admin'] }
];

const RUNNING_PROGRESS_OPTIONS = ['10%', '20%', '30%', '35%', '40%', '45%', '50%', '55%', '57%', '60%', '70%', '80%', '90%'];

const REGISTRATION_PROGRESS = [
  { label: 'All', value: '', roles: ['owner', 'manager', 'admin'] },
  { label: 'Running', value: 'running', roles: ['owner', 'manager', 'admin'] },
  // { label: '1%', value: '1%', roles: ['owner', 'manager', 'admin'] },
  { label: '10%', value: '10%', roles: ['owner', 'manager', 'admin'] },
  { label: '20%', value: '20%', roles: ['owner', 'manager', 'admin'] },
  { label: '30%', value: '30%', roles: ['owner', 'manager', 'admin'] },
  { label: '35%', value: '35%', roles: ['owner', 'manager', 'admin'] },
  { label: '40%', value: '40%', roles: ['owner', 'manager', 'admin'] },
  { label: '45%', value: '45%', roles: ['owner', 'manager', 'admin'] },
  { label: '50%', value: '50%', roles: ['owner', 'manager', 'admin'] },
  { label: '55%', value: '55%', roles: ['owner', 'manager', 'admin'] },
  { label: '57%', value: '57%', roles: ['owner', 'manager', 'admin'] },
  { label: '60%', value: '60%', roles: ['owner', 'manager', 'admin'] },
  { label: '70%', value: '70%', roles: ['owner', 'manager', 'admin'] },
  { label: '80%', value: '80%', roles: ['owner', 'manager', 'admin'] },
  { label: '90%', value: '90%', roles: ['owner', 'manager', 'admin'] },
  { label: '100%', value: '100%', roles: ['owner', 'manager', 'admin'] }
];

const SEARCH_EXCLUDE_KEY = ['sys_case_uid', 'owner_uid', 'case_status_description'];
const DR_SEARCH_EXCLUDE_STATUS = ['Waiting', 'Open', 'Reject'];

const dateOffset = (dateValue, hh = 0, mm = 0, ss = 0, ms = 0) => {
  const date = new Date(dateValue);
  date.setHours(hh);
  date.setMinutes(mm);
  date.setSeconds(ss);
  date.setMilliseconds(ms);
  return date;
};

const diffDays = (dateStr) => {
  const date = dateOffset(dateStr);
  const now = dateOffset(new Date());
  if (Number.isNaN(Number(date.getTime()))) {
    return NaN;
  }
  const timeDifference = now.getTime() - date.getTime();
  const differentDays = Math.ceil(timeDifference / (1000 * 3600 * 24));
  return differentDays;
};

const checkOnDateRange = (myDate, range1, range2) => {
  // myDate 可為空，不需檢查
  // if (!myDate) {
  //   return false;
  // }
  // check range1 <= myDate <= range2
  const myDateValue = dateOffset(myDate);
  if (range1 && dateOffset(range1) > myDateValue) {
    return false;
  }
  if (range2 && dateOffset(range2) < myDateValue) {
    return false;
  }
  return true;
};

const filterEngine = (rows, filterCondition) => {
  const filterStr = filterCondition.strictFilterValue || '';
  const greedyFilterStr = filterCondition.greedyFilterValue || '';
  const statusFilter = filterCondition.statusFilterValue || [];
  const updateRangeFilter = filterCondition.updateRangeFilterValue || 0;
  const productTypeFilter = filterCondition.productTypeFilterValue || [];
  const progressFilter = filterCondition.progressFilterValue || [];
  const caseApprovalFilter = [filterCondition.caseApprovalDateFilterLeftValue || '', filterCondition.caseApprovalDateFilterRightValue || ''];
  const createStampFilter = [filterCondition.createStampFilterLeftValue || '', filterCondition.createStampFilterRightValue || ''];
  const estimatedClosingFilter = [filterCondition.estimatedClosingFilterLeftValue || '', filterCondition.estimatedClosingFilterRightValue || ''];
  const filterOwner = filterCondition.ownerFilterValue || [];
  const filterOwnerDataForm = filterCondition.dataFormFilterValue || [];
  const productFilterValue = filterCondition.productFilterValue || [];

  const result = rows.filter((row) => {
    const myFilterArg = filterStr.toLowerCase().trim().split(' ');
    const myGreedyFilterArg = greedyFilterStr.toLowerCase().trim().split(' ');
    const isOnStatue = statusFilter.includes(row.case_status);
    const { range1, range2 } = UPDATE_TIME_RANGE[updateRangeFilter];
    const diffDay = diffDays(row.sys_last_update_stamp)
    const isInRange = (range1 <= diffDay && diffDay <= range2) || diffDay < 0;
    const isOnProductType = productTypeFilter.some((ptfStr) => {
      return row.product_type.includes(ptfStr);
    });

    const additionProgressOptions = progressFilter.includes('running') ? RUNNING_PROGRESS_OPTIONS : [];
    const isOnProgress = [...additionProgressOptions, ...progressFilter].some((pfStr) => {
      return row.status_progress.includes(pfStr);
    });

    const isOnCaseApproval = checkOnDateRange(row.case_approval_date, caseApprovalFilter[0], caseApprovalFilter[1]);
    const isOnCreateStamp = checkOnDateRange(row.sys_create_stamp, createStampFilter[0], createStampFilter[1]);
    const isOnDateRange = checkOnDateRange(row.status_estimatedClosing, estimatedClosingFilter[0], estimatedClosingFilter[1]);
    const isOwner = filterOwner.length > 0 ? filterOwner.includes(row.owner_name) : true;
    const isOwnerDataForm = filterOwnerDataForm.length > 0 ? filterOwnerDataForm.includes(row.data_form) : true;
    // case_status_description is product
    const isProduct = productFilterValue.length > 0 ? productFilterValue.some((pfv) => {
      return row.case_status_description.includes(pfv);
    }) : true;

    if (!isInRange
      || !isOnCaseApproval
      || !isOnCreateStamp
      || !isOnDateRange
      || (productTypeFilter.length > 0 && !isOnProductType)
      || (statusFilter.length > 0 && !isOnStatue)
      || (progressFilter.length > 0 && !isOnProgress)
      || !isOwner
      || !isOwnerDataForm
      || !isProduct
    ) {
      return false;
    }

    const checked = myFilterArg.every((sfStr) => {
      return Object.keys(row).some((key) => {
        if (SEARCH_EXCLUDE_KEY.includes(key) || (DR_SEARCH_EXCLUDE_STATUS.includes(row.case_status) && key === 'agent_DR_Number')) {
          return false;
        }
        return row[key].toLowerCase().includes(sfStr);
      });
    });

    const greedyChecked = myGreedyFilterArg.length > 0 ? myGreedyFilterArg.some((gfStr) => {
      return Object.keys(row).some((key) => {
        if (SEARCH_EXCLUDE_KEY.includes(key) || (DR_SEARCH_EXCLUDE_STATUS.includes(row.case_status) && key === 'agent_DR_Number')) {
          return false;
        }
        return row[key].toLowerCase().includes(gfStr);
      });
    }) : true;

    return checked && greedyChecked;
  });
  return result;
};

const optionRoleFilter = (role, options) => {
  const currentRole = role || 'owner';
  return options.filter((opt) => {
    return opt.roles.includes(currentRole);
  });
};

const FILTER_CONSTANTS = {
  SEARCH_STATUS,
  UPDATE_TIME_RANGE,
  PRODUCT_TYPE,
  REGISTRATION_PROGRESS,
  SEARCH_EXCLUDE_KEY,
  DR_SEARCH_EXCLUDE_STATUS
};

export {
  FILTER_CONSTANTS,
  filterEngine,
  optionRoleFilter
};
