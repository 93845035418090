import { CONSTANTS } from "./constants";

const initialSheetDataForm = [
  { name: 'default' }
];

export default (state = initialSheetDataForm, action) => {
  switch (action.type) {
    case CONSTANTS.SET_SHEET_DATA_FORM :
      return action.value;
    default:
      return state;
  }
}
