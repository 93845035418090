import React, { useEffect, useState } from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { useAuth0 } from "@auth0/auth0-react";
import { connect } from 'react-redux';

import {
  setAuthInfo,
  setDealList,
  setLoading,
  setAccountInfo,
  setNotifyRule,
  setOrgMember,
  setScreen
} from './actions';
import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';

import {
  Dashboard as DashboardView,
  ProductList as ProductListView,
  UserList as UserListView,
  Typography as TypographyView,
  Icons as IconsView,
  Account as AccountView,
  Settings as SettingsView,
  SignUp as SignUpView,
  SignIn as SignInView,
  UserManager as UserManagerView,
  NotFound as NotFoundView,
  Analyz as AnalyzView
} from './views';

import envConfig from './configuration/env.json';

import { DealList as DealListView, DealListDetail as DealListDetailView} from './views/DealList';
import { httpRequest, reqObj2From, form2ReqObj } from './lib';

// const NEED_VERIFY_MESSAGE = "Please verify your email before logging in.";

const MEDIUM_WIDTH = 950;
const MOBILE_WIDTH = 768;

const getScreenSize = () => {
  if (window.innerWidth < MOBILE_WIDTH) {
    return 'mobile';
  } else if (window.innerWidth < MEDIUM_WIDTH) {
    return 'medium';
  } else {
    return 'large';
  }
};

const Routes = (props) => {
  const { user, isAuthenticated, getAccessTokenSilently, error } = useAuth0();
  const initProfile = (token, callback) => {
    httpRequest('GET', '/api/account', {}, token, (statusCode, body) => {
      if (!body.status) {
        return;
      }
      props.setAccountInfo({...body.info});
      callback();
    });
  };
  const initCaseRule = (token) => {
    httpRequest('GET', '/api/notifyrule', {}, token, (statusCode, response) => {
      const { status, message, notifyRule } = response;
      if (!status) { return; }
      props.setNotifyRule(notifyRule);
    });
  }
  const initOrgMember = (token) => {
    httpRequest('GET', '/api/manager/orgmember', {}, token, (statusCode, response) => {
      const { status, message, members } = response;
      if (!status) { return; }
      props.setOrgMember(members);
    });
  }
  const loadDealList = (token, formInfos) => {
    httpRequest('GET', '/api/dealtrack', {}, token, (statusCode, response) => {
      const { status, message, data } = response;
      if (!status) { return; }
      const formatData = data.map((row) => {
        const ownerObj = {
          owner_name: row.owner.name,
          owner_email: row.owner.email,
          owner_uid: row.owner.user_uid
        };
        const formInfoIndex = 0; // use default dataform
        return { ...reqObj2From(formInfos[formInfoIndex], row), ...ownerObj };
      });
      props.setDealList(formatData);
      props.setLoading(false);
    });
  };

  const initFormMap = (token, callback) => {
    callback(props.formInfos);
  };

  const handleWindowSizeChange = () => {
    props.setScreen(getScreenSize());
  };

  useEffect(() => {
    if (isAuthenticated) {
      getAccessTokenSilently({
        audience: envConfig.audience,
        scope: envConfig.scope
      }).then((accessToken) => {
        // console.log({ accessToken });
        props.setAuthInfo({ ...user, accessToken });
        initProfile(accessToken, () => {
          // When a request is made at the same time, two new users will be created in the initialization process
          initFormMap(accessToken, (formInfos) => {
            loadDealList(accessToken, formInfos);
          });
        });
        initCaseRule(accessToken);
        initOrgMember(accessToken);
      }).catch((err) => {
        // console.log(err);
      });
    }
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, [isAuthenticated]);

  return (
    <Switch>
      {/****************************
      <Redirect
        exact
        from="/"
        to="/dashboard"
      />
      <RouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/"
      />
      <RouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/dashboard"
      />
      ****************************/}
      {/****************************
      <RouteWithLayout
        component={UserListView}
        exact
        layout={MainLayout}
        path="/users"
      />
      ****************************/}
      <RouteWithLayout
        component={DealListView}
        exact
        layout={MainLayout}
        path="/"
      />
      <RouteWithLayout
        component={DealListView}
        exact
        layout={MainLayout}
        path="/pipeline"
      />
      <RouteWithLayout
        component={DealListDetailView}
        layout={MainLayout}
        path="/pipeline/:caseid"
      />
      {/****************************
      <RouteWithLayout
        component={TypographyView}
        exact
        layout={MainLayout}
        path="/typography"
      />
      <RouteWithLayout
        component={IconsView}
        exact
        layout={MainLayout}
        path="/icons"
      />
      ****************************/}
      <RouteWithLayout
        component={AnalyzView}
        exact
        layout={MainLayout}
        path="/analyz"
      />
      <RouteWithLayout
        component={AccountView}
        exact
        layout={MainLayout}
        path="/account"
      />
      <RouteWithLayout
        component={UserManagerView}
        exact
        layout={MainLayout}
        path="/usermanager"
      />
      {/****************************
      <RouteWithLayout
        component={SettingsView}
        exact
        layout={MainLayout}
        path="/settings"
      />
      ****************************/}
      <RouteWithLayout
        component={SignUpView}
        exact
        layout={MinimalLayout}
        path="/sign-up"
      />
      <RouteWithLayout
        component={SignInView}
        exact
        layout={MinimalLayout}
        path="/sign-in"
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};


const mapStateToProps = (state) => {
  const { formInfos } = state;
  return { formInfos };
};

export default connect(mapStateToProps, {
  setAuthInfo,
  setDealList,
  setLoading,
  setAccountInfo,
  setNotifyRule,
  setOrgMember,
  setScreen
})(Routes);
