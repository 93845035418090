import React, { forwardRef, useState, useEffect } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { NavLink as RouterLink } from 'react-router-dom';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Tooltip from '@material-ui/core/Tooltip';
import { confirmAlert } from 'react-confirm-alert';
import ExcelReaderButton from './ExcelReaderButton';
import ExcelExportButton from './ExcelExportButton';
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/Refresh';
import CreateIcon from '@material-ui/icons/Create';
import PropTypes from 'prop-types';
import { FilterComponents } from '../../components';
import { lighten, makeStyles } from '@material-ui/core/styles';
import { row2ReqObj } from '../../lib';

const FILTER_ACCORDION_STR = 'accordion';
const FILTER_FLATTEN_STR = 'flatten';
const FILTER_DIALOG_STR = 'dialog';

const MOBILE_SCREEN_STR = 'mobile';
const MEDIUM_SCREEN_STR = 'medium';
const LARGE_SCREEN_STR = 'large';

const SCREEN_SIZE_INFO_MAP = {
  [MOBILE_SCREEN_STR]: {
    filterMode: FILTER_DIALOG_STR,
    tools: {
      refresh: true,
      create: true,
      excelImport: false,
      excelExport: true,
      filter: true,
    }
  },
  [MEDIUM_SCREEN_STR]: {
    filterMode: FILTER_ACCORDION_STR,
    tools: {
      refresh: true,
      create: true,
      excelImport: true,
      excelExport: true,
      filter: true,
    }
  },
  [LARGE_SCREEN_STR]: {
    filterMode: FILTER_FLATTEN_STR,
    tools: {
      refresh: true,
      create: true,
      excelImport: true,
      excelExport: true,
      filter: true,
    }
  }
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
}));

const headMapConst = (formInfo) => {
  const json = {};
  formInfo.forEach((map, index) => {
    json[`${map.type}_${map.key}`] = index;
  });
  return json;
};

const validateValueInLoogup = (value, attr) => {
  return attr.lookup.some((option) => {
    return option.value && option.value === value;
  });
};

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));

const DealListToolbar = (props) => {
  const classes = useToolbarStyles();

  const onExcelImportSubmit = (_dataForm, _data) => {
    // const formInfoIndex = props.formInfos.findIndex((formInfo) => {
    //   return formInfo.name === _dataForm;
    // });
    const formInfoIndex = 0; // use default dataform
    if (formInfoIndex === -1) {
      confirmAlert({
        title: 'Warning',
        message: props.intl.formatMessage({ id: 'please_select_data_form' }),
        buttons: [{ label: 'Yes' }],
        overlayClassName: 'React-confirm-alert-cust-overlay'
      });
      return;
    }
    // slice headers and row number.
    const data = _data.slice(1).map((_d) => {
      return _d.slice(1).map((_vobj) => {
        return _vobj.value;
      });
    });
    // auto reomve empty row
    const subFrom = row2ReqObj(props.formInfos[formInfoIndex], data);
    if (subFrom.length === 0) {
      return;
    }
    // TODO: remove dataform
    const dataForm = props.formInfos[formInfoIndex].name;
    props.onExcelImportClick(props.formInfos[formInfoIndex].name, subFrom);
  };

  const onExcelImportValidate = (_dataForm, _data) => {
    // TODO: remove dataform
    // props.formInfos
    // const formInfoIndex = props.formInfos.findIndex((formInfo) => {
    //   return formInfo.id === _dataForm.id;
    // });
    const formInfoIndex = 0; // use default datafrom

    const headerObj = props.formInfos[formInfoIndex].map.filter((titleOjb) => {
      return titleOjb.allowEditStage.includes('Waiting');
    });

    if (formInfoIndex === -1) {
      // alert incorrect _dataForm
      confirmAlert({
        title: 'Warning',
        message: props.intl.formatMessage({ id: 'incorrect_data_form' }),
        buttons: [{ label: 'Yes' }],
        closeOnClickOutside: false,
        overlayClassName: 'React-confirm-alert-cust-overlay'
      });
      return false;
    }
    const mapConst = headMapConst(headerObj);

    const validateProgressIndex = mapConst.status_progress + 1;
    const validateProuctTypeIndex = mapConst.product_type + 1;
    // const validateProuctCategoryIndex = mapConst.case_status_description + 1;

    const statusProgressAttr = headerObj[mapConst.status_progress];
    const productTypeAttr = headerObj[mapConst.product_type];
    // const caseStatusDescriptionAttr = headerObj[mapConst.case_status_description];

    let alertMessage = '';
    let alertMessageId = '';
    for (let i = 1; i < _data.length; i += 1) {
      const row = _data[i];
      const isEmtpyRow = row.every((cell, rIndex) => {
        return (rIndex === 0) || !cell.value.trim();
      });
      if (isEmtpyRow) {
        break;
      }
      if (!validateValueInLoogup(row[validateProgressIndex].value, statusProgressAttr)) {
        // Alert Error
        alertMessage = `Status Progress is incorrect in row ${i}`;
        alertMessageId = 'status_progress_incorrect_in_row';
      } else if (!validateValueInLoogup(row[validateProuctTypeIndex].value, productTypeAttr)) {
        // Alert Error
        alertMessage = `Product Type is incorrect in row ${i}`;
        alertMessageId = 'product_type_incorrect_in_row';
      }
      // else if (!validateValueInLoogup(row[validateProuctCategoryIndex].value, caseStatusDescriptionAttr)) {
      //   // Alert Error
      //   alertMessage = `Case Status Description is incorrect in row ${i}`;
      //   alertMessageId = 'case_status_description_incorrect_in_row';
      // }
      if (alertMessage) {
        confirmAlert({
          title: 'Warning',
          // TODO: edit variable in message
          message: props.intl.formatMessage({ id: alertMessageId }, { index: i }),
          buttons: [{ label: 'Yes' }],
          closeOnClickOutside: false,
          overlayClassName: 'React-confirm-alert-cust-overlay'
        });
        return false;
      }
    }
    return true;
  };
  // default dataform index is 0.
  const productFieldInfo = props.formInfos[0].map.find((formObj) => {
    return formObj.type === 'case' && formObj.key === 'status_description';
  });

  return (
    <Toolbar className={classes.root}>
      <Typography className={classes.title} style={{ fontSize:'30px' }} variant='h6' id='tableTitle' component='div'>
        <FormattedMessage id='pipeline_title' defaultMessage='Pipeline'/>
      </Typography>

      {
        SCREEN_SIZE_INFO_MAP[props.screen].tools.refresh &&
        <Tooltip title={props.intl.formatMessage({ id: 'refresh' })}>
          <IconButton onClick={props.onReloadClick}>
            <RefreshIcon fontSize='large' color='primary'/>
          </IconButton>
        </Tooltip>
      }
      {
        SCREEN_SIZE_INFO_MAP[props.screen].tools.create &&
        <Tooltip title={props.intl.formatMessage({ id: 'add' })}>
          <IconButton aria-label='editor' component={CustomRouterLink} to={props.addRedirectUrl}>
            <CreateIcon fontSize='large' color='primary'/>
          </IconButton>
        </Tooltip>
      }

      {
        SCREEN_SIZE_INFO_MAP[props.screen].tools.excelImport &&
        <ExcelReaderButton onValidate={onExcelImportValidate} onSubmit={onExcelImportSubmit} formInfos={props.formInfos}/>
      }
      {
        SCREEN_SIZE_INFO_MAP[props.screen].tools.excelExport &&
        <ExcelExportButton dealList={props.excelExportDeals}/>
      }
      {
        SCREEN_SIZE_INFO_MAP[props.screen].tools.refresh &&
        <FilterComponents
          account={props.account}
          mode={SCREEN_SIZE_INFO_MAP[props.screen].filterMode}
          ownerList={props.ownerList}
          dataFormList={[]}
          productList={productFieldInfo.lookup.filter((lkObj) => {
            return lkObj.value;
          })}
          filterInfo={props.filterInfo}
          onFilterInfoChange={props.onFilterInfoChange}
          enableStrictFilter={true}
          enableGreedyFilter={true}
          enableStatusFilter={true}
          enableUpdateRangeFilter={true}
          enableProductTypeFilter={true}
          enableProgressFilter={true}
          enableCreateStampFilter={true}
          enableEstimatedClosingFilter={true}
          enableOwnerFilter={true}
          enableDataFormFilter={false}
          enableProductFilter={true}
        /> 
      }
    </Toolbar>
  );
};

DealListToolbar.propTypes = {
  account: PropTypes.object.isRequired,
  formInfos: PropTypes.any.isRequired,
  filterInfo: PropTypes.array.isRequired,
  onFilterInfoChange: PropTypes.func.isRequired,
  ownerList: PropTypes.array.isRequired,
  dataFormList: PropTypes.array.isRequired,
  onReloadClick: PropTypes.func.isRequired,
  addRedirectUrl: PropTypes.string.isRequired, // '/pipeline/new'
  onExcelImportClick: PropTypes.func.isRequired,
  excelExportDeals: PropTypes.array.isRequired
};

export default injectIntl(DealListToolbar);
