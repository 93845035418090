import React, { forwardRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { confirmAlert } from 'react-confirm-alert';
import { connect } from 'react-redux';
// material ui
import { makeStyles } from '@material-ui/core/styles';
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TextField from '@material-ui/core/TextField';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Select from "@material-ui/core/Select";
import MenuItem from '@material-ui/core/MenuItem';
import Snackbar from '@material-ui/core/Snackbar';
// material ui icons
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import EmailIcon from '@material-ui/icons/Email';
// cust
import DealListToolbar from './DealListToolbar';
import EmailDialog from './emailDialog';
import {
  setAccountInfo,
  setOrgMember,
  setDealList,
  setLoading,
  // setFilterStr,
  // setStatusFilter,
  // setUpdateRangeFilter,
  // setProductTypeFilter,
  // setProgressFilter,
  // setEstimatedClosingFilter,
  setFilterInfo,
  setOrder,
  setOrderBy,
  // setFilterOwner,
  // setGreedyFilterStr,
  // setFilterDataForm
} from '../../actions'; // redux action
import { filterEngine } from '../../components/FilterComponents/filterLib';
import { checkEngine } from '../../common/ruleEngine';
import Loader from '../../image/Loading.gif';
import { httpRequest, reqObj2From, form2ReqObj } from '../../lib';
import headCells from '../../configuration/pipeline.json';
import streamlineHeadCells from '../../configuration/streamlinePipeline.json';
// import 'react-confirm-alert/src/react-confirm-alert.css';
import '../../assets/scss/cust-react-confirm-alert.scss';

import './deallist.css';
import "date-fns";

// const HIDENT_DR_NUMBER_CASE_STATUS = ['Archive', 'Expired'];
const HIDENT_DR_NUMBER_CASE_STATUS = ['Expired'];

const MOBILE_SCREEN_STR = 'mobile';
const MEDIUM_SCREEN_STR = 'medium';
const LARGE_SCREEN_STR = 'large';

const SCREEN_SIZE_INFO_MAP = {
  [MOBILE_SCREEN_STR]: {
    streamline: true
  },
  [MEDIUM_SCREEN_STR]: {
    streamline: false
  },
  [LARGE_SCREEN_STR]: {
    streamline: false
  }
};

const renderShortName = (row) => {
  const sellerCompony = row['seller_compony_tw'].slice(0, 4);
  // const sellerName = row['seller_name'];
  const userCompony = (row['user_compony_tw'] || row['user_compony_en']).slice(0, 4);;
  // const userName = row['user_name'];

  if (sellerCompony && userCompony) {
    return `${sellerCompony}-${userCompony}`;
  } else if (sellerCompony) {
    return sellerCompony;
  } else if (userCompony) {
    return userCompony;
  } else {
    return 'N/A';
  }
}

const dateOffset = (dateValue, hh = 0, mm = 0, ss = 0, ms = 0) => {
  const date = new Date(dateValue);
  date.setHours(hh);
  date.setMinutes(mm);
  date.setSeconds(ss);
  date.setMilliseconds(ms);
  return date;
};

const diffDays = (dateStr) => {
  const date = dateOffset(dateStr);
  const now = dateOffset(new Date());
  if (Number.isNaN(Number(date.getTime()))) {
    return NaN;
  }
  const timeDifference = now.getTime() - date.getTime();
  const differentDays = Math.ceil(timeDifference / (1000 * 3600 * 24));
  return differentDays;
};

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));

// 專案名稱, proposal_name
// 使用者名稱, user_name
// 產品明細, product_detail
// 專案狀態%, status_progress

const descendingComparator = (a, b, orderBy) => {
  if (['sys_create_stamp', 'sys_last_update_stamp', 'status_estimatedClosing', 'case_approval_date'].includes(orderBy)) {
    // compare time
    const aTime = new Date(a[orderBy]).getTime();
    const bTime = new Date(b[orderBy]).getTime();
    if (Number.isNaN(Number(aTime))) {
      return 1;
    }
    if (Number.isNaN(Number(bTime))) {
      return -1;
    }
    return aTime - bTime;
  } else if(orderBy === 'user_compony_tw') {
    const valueA = a.user_compony_tw || a.user_compony_en;
    const valueB = b.user_compony_tw || b.user_compony_en;
    if (valueB < valueA) {
      return -1;
    }
    if (valueB > valueA) {
      return 1;
    }
    return 0;
  } else {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
}

const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

const EnhancedTableHead = (props) => {
  const { classes, order, orderBy, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        <TableCell style={{minWidth: '65px'}} padding="checkbox">
          <FormattedMessage id="edit"/>
        </TableCell>
        {
          !SCREEN_SIZE_INFO_MAP[props.screen].streamline &&
          ['manager', 'admin'].includes(props.role) &&
          <TableCell style={{minWidth: '100px'}} padding="checkbox">
            <FormattedMessage id="send_email_title"/>
          </TableCell>
        }
        {
          SCREEN_SIZE_INFO_MAP[props.screen].streamline &&
          <>
            <TableCell style={{minWidth: '100px'}} padding="checkbox">
              <FormattedMessage id="short_name"/>
            </TableCell>
            <TableCell
              key={'sys_last_update_stamp'}
              style={{ minWidth: '100px' }}
              align={'center'}
              padding={'none'}
              sortDirection={orderBy === 'sys_last_update_stamp' ? order : false}
            >
              <TableSortLabel
                active={orderBy === 'sys_last_update_stamp'}
                direction={orderBy === 'sys_last_update_stamp' ? order : 'asc'}
                onClick={createSortHandler('sys_last_update_stamp')}
              >
                <FormattedMessage id={'last_update_stamp'}/>
                {orderBy === 'sys_last_update_stamp' ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          </>
        }
        {
          (SCREEN_SIZE_INFO_MAP[props.screen].streamline ? streamlineHeadCells : headCells).map((headCell) => (
            <TableCell
              key={headCell.id}
              style={{ width: headCell.width, minWidth: headCell.minWidth }}
              align={'center'}
              padding={'none'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                <FormattedMessage id={headCell.label_id}/>
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))
        }
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  role: PropTypes.string.isRequired
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  shineRow: {
    '&:hover': {
      backgroundColor: '#DDDDDD !important'
    },
    background: '#3dabe248',
  },
  warnRow: {
    '&:hover': {
      backgroundColor: '#DDDDDD !important'
    },
    background: '#FFD0D0'
  },
  calmRow: {
    '&:hover': {
      backgroundColor: '#DDDDDD !important'
    }
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  searchInput: {
    marginRight: theme.spacing(1)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  }
}));

const DealList = (props) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [openEmailDialog, setOpenEmailDialog] = React.useState(false);
  const [chooseForm, setChooseForm] = React.useState({});
  const [rowIndex, setRowIndex] = React.useState(-1);
  const [editColumnIndex, setEditColumnIndex] = React.useState(-1);
  const [editColumnValue, setEditColumnValue] = React.useState(null);
  const [snackMsg, setSnackMsg] = React.useState('');

  useEffect(() => {
    // Scroll to top
    window.scrollTo(0, 0);
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = props.orderBy === property && props.order === 'asc';
    props.setOrder(isAsc ? 'desc' : 'asc');
    props.setOrderBy(property);
    setRowIndex(-1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const initProfile = (token, callback) => {
    httpRequest('GET', '/api/account', {}, token, (statusCode, body) => {
      if (!body.status) {
        callback();
        return;
      }
      props.setAccountInfo({...body.info});
      callback();
    });
  };

  const initOrgMember = (token) => {
    httpRequest('GET', '/api/manager/orgmember', {}, token, (statusCode, response) => {
      const { status, message, members } = response;
      if (!status) {
        return;
      }
      props.setOrgMember(members);
    });
  }

  const loadDealList = (token, callback) => {
    httpRequest('GET', '/api/dealtrack', {}, token, (statusCode, response) => {
      const { status, message, data } = response;
      if (!status) {
        callback();
        return;
      }
      const formatData = data.map((row) => {
        const ownerObj = {
          owner_name: row.owner.name,
          owner_email: row.owner.email,
          owner_uid: row.owner.user_uid
        };
        // const formInfoIndex = props.formInfos.findIndex((formInfo) => {
        //   return formInfo.name === row.dataForm;
        // });
        const formInfoIndex = 0; // use default datafrom
        return { ...reqObj2From(props.formInfos[formInfoIndex], row), ...ownerObj };
      });
      props.setDealList(formatData);
      callback();
    });
  };

  const onReloadDeal = (event) => {
    props.setLoading(true);

    initOrgMember(props.auth.accessToken);
    initProfile(props.auth.accessToken, () => {
      // When a request is made at the same time, two new users will be created in the initialization process
      loadDealList(props.auth.accessToken, () => {
        props.setLoading(false);
        // props.setDealList(formatData);
      });
    });
  }

  const onEmailDialog = (form) => {
    const cform = JSON.parse(JSON.stringify(form));
    setChooseForm(cform);
    setOpenEmailDialog(true);
  };

  const onCloseEmailDialog = () => {
    setOpenEmailDialog(false);
  };

  const onSubmitEmail = (form) => {
    /*
    form: {
      receiver: Array<string>,
      ccReceiver: Array<string>,
      bccReceiver: Array<string>,
      emailSubject: string,
      emailContent: string
    }
    */
    props.setLoading(true);
    httpRequest('POST', '/api/email', form, props.auth.accessToken, (statusCode, body) => {
      // TODO: add response
      props.setLoading(false);
      setOpenEmailDialog(false);
      confirmAlert({
        title: body.status ? 'Success' : 'Warning',
        message: body.status ? 'Success' : body.message,
        buttons: [{ label: 'Yes' }],
        closeOnClickOutside: false
      });
    });
  };

  const onExcelImportDeal = (dataForm, rows) => {
    props.setLoading(true);
    httpRequest('PUT', '/api/dealtrack/rows', { rows }, props.auth.accessToken, (statusCode, body) => {
      if (!body.status) {
        // show error and reload
        confirmAlert({
          title: 'Warning',
          message: body.message,
          buttons: [
            { label: 'Yes', onClick: () => window.location.reload() }
          ]
        });
        props.setLoading(false);
        return;
      }
      httpRequest('GET', '/api/dealtrack', {}, props.auth.accessToken, (statusCode, response) => {
        const { status, message, data } = response;
        const formatData = data.map((row) => {
          const ownerObj = {
            owner_name: row.owner.name,
            owner_email: row.owner.email,
            owner_uid: row.owner.user_uid
          };
          // const formInfoIndex = props.formInfos.findIndex((formInfo) => {
          //   return formInfo.name === row.dataForm;
          // });
          const formInfoIndex = 0; // use default datafrom
          return { ...reqObj2From(props.formInfos[formInfoIndex], row), ...ownerObj };
        });
        confirmAlert({
          title: 'Success',
          message: 'Success',
          buttons: [{ label: 'Yes' }]
        });
        props.setLoading(false);
        props.setDealList(formatData);
      });
    });
  };
  const onFilterInfoChange = (key, value) => {
    const json = JSON.parse(JSON.stringify(props.filterInfo));
    json[key] = value;
    setRowIndex(-1);
    props.setFilterInfo(json);
  };

  const onRowClick = (index) => (event) => {
    if (rowIndex !== index) {
      setEditColumnValue(null);
      setEditColumnIndex(-1);
    }
    setRowIndex(index);
  };

  const onEditColumnClick = (value, index) => (event) => {
    setEditColumnValue(value);
    setEditColumnIndex(index);
  };

  const onEditSaveColumnClick = (row, formInfo, key) => (event) => {
    const originDealList = JSON.parse(JSON.stringify(props.dealList));
    const newRow = JSON.parse(JSON.stringify(row));
    newRow[key] = editColumnValue;

    const endpoint = `/api/dealtrack/${newRow.sys_case_uid}`;
    const method = 'POST';
    const reqBody = { data: form2ReqObj(formInfo, [newRow])[0] };

    const updateRowIndex = originDealList.findIndex((deal) => {
      return deal.sys_case_uid === row.sys_case_uid;
    });
    originDealList[updateRowIndex] = newRow;
    props.setDealList(originDealList);
    httpRequest(method, endpoint, reqBody, props.auth.accessToken, (statusCode, body) => {
      setSnackMsg(`Update: ${body.message}`);
    });

    setEditColumnValue(null);
    setEditColumnIndex(-1);
  };

  // const emptyRows = rowsPerPage - Math.min(rowsPerPage, props.dealList.length - page * rowsPerPage);
  const focusRows = filterEngine(stableSort(props.dealList, getComparator(props.order, props.orderBy)), props.filterInfo);
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <DealListToolbar
          screen={props.screen}
          account={props.account}
          formInfos={props.formInfos}
          filterInfo={props.filterInfo}
          onFilterInfoChange={onFilterInfoChange}
          onReloadClick={onReloadDeal}
          addRedirectUrl={'/pipeline/new'}
          onExcelImportClick={onExcelImportDeal}
          excelExportDeals={filterEngine(stableSort(props.dealList, getComparator(props.order, props.orderBy)), props.filterInfo)}
          ownerList={props.orgMember.map((orgM) => {
            return { label: orgM.name, value: orgM.name };
          })}
          dataFormList={props.sheetDataForm.map((dataFormCfg) => {
            return { label: dataFormCfg.name, value: dataFormCfg.name };
          })}
        />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'small'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              screen={props.screen}
              classes={classes}
              order={props.order}
              orderBy={props.orderBy}
              onRequestSort={handleRequestSort}
              rowCount={props.dealList.length}
              role={props.account.role}
            />
            <TableBody>
              {focusRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  // const labelId = `enhanced-table-checkbox-${index}`;
                  // check role to show diff css in here.
                  const isWarn = props.notifyRule.some((myRule) => {
                    return checkEngine(myRule, row);
                  });
                  const rowClassName = isWarn ? classes.warnRow :
                    (row.case_status === 'Waiting') ? classes.shineRow : classes.calmRow;

                  // const dataFormIndex = props.formInfos.findIndex((formInfo) => {
                  //   return formInfo.name === row.dataForm;
                  // });
                  const dataFormIndex = 0; // use default datafrom

                  // props.formInfos[dataFormIndex]
                  return (
                    <TableRow hover role='checkbox' tabIndex={-1} key={`${index}`} className={rowClassName} onClick={onRowClick(index)}>
                      <TableCell padding="checkbox">
                        <IconButton
                          aria-label="editor"
                          component={CustomRouterLink}
                          to={`/pipeline/${row.sys_case_uid}`}
                        >
                          {
                            HIDENT_DR_NUMBER_CASE_STATUS.includes(row.case_status) ?
                            <VisibilityIcon style={{ color:'#dadada' }}/>:<EditIcon style={{ color:'#ffb549' }}/>
                          }
                        </IconButton>
                      </TableCell>
                      {
                        !SCREEN_SIZE_INFO_MAP[props.screen].streamline &&
                        ['manager', 'admin'].includes(props.account.role) &&
                        <TableCell padding="checkbox" style={{textAlign: 'center'}}>
                          <IconButton aria-label="send_email" onClick={() => onEmailDialog(row)}>
                            <EmailIcon />
                          </IconButton>
                        </TableCell>
                      }
                      {
                        !SCREEN_SIZE_INFO_MAP[props.screen].streamline &&
                        headCells.map((headCell) => {
                          if (headCell.id === 'agent_DR_Number') {
                            return (
                              <TableCell align="left">{row.case_status === 'Approved' ? row.agent_DR_Number : "N/A"}</TableCell>
                            );
                          }
                          if (headCell.id === 'sys_last_update_stamp') {
                            return (
                              <TableCell align="left">{diffDays(row.sys_last_update_stamp)}日</TableCell>
                            );
                          }
                          if (['status_estimatedClosing'].includes(headCell.id)) {
                            return (
                              <TableCell align="left" padding="none">{row[headCell.id].split('T')[0]}</TableCell>
                            );
                          }
                          if (headCell.id === 'user_compony_tw') {
                            return (
                              <TableCell align="left" padding="none">{row.user_compony_tw || row.user_compony_en}</TableCell>
                            );
                          }
                          return (
                            <TableCell align="left" padding="none">{row[headCell.id]}</TableCell>
                          );
                        })
                      }
                      {/* streamlineHeadCells */}
                      {
                        SCREEN_SIZE_INFO_MAP[props.screen].streamline &&
                        <>
                          <TableCell align="left" padding="none">
                            {renderShortName(row)}
                          </TableCell>
                          <TableCell align="left">{diffDays(row.sys_last_update_stamp)}日</TableCell>
                        </>
                      }
                      {
                        SCREEN_SIZE_INFO_MAP[props.screen].streamline &&
                        streamlineHeadCells.map((headCell, cIndex) => {
                          if (cIndex === editColumnIndex && index === rowIndex) {
                            const columnInfoIndex = props.formInfos[dataFormIndex].map.findIndex((mapInfo) => {
                              const columnKey = `${mapInfo.type}_${mapInfo.key}`;
                              return headCell.id === columnKey;
                            });
                            const columnInfo = props.formInfos[dataFormIndex].map[columnInfoIndex];
                            const columnKey = `${columnInfo.type}_${columnInfo.key}`;

                            const renderColumnInput = () => {
                              if (columnInfo.fieldType === 'select') {
                                // select 
                                // columnInfo.lookup: Array<{ label: string, value: string }>
                                return (
                                  <Select
                                    style={{ width: '100%'}}
                                    value={editColumnValue || ''}
                                    displayEmpty={true}
                                    onChange={(event) => {
                                      setEditColumnValue(event.target.value);
                                    }}
                                  >
                                    {
                                      columnInfo.lookup.map((item) => {
                                        const displayLabelParts = item.label.split(' (');
                                        if (displayLabelParts.length === 2) {
                                          return (
                                            <MenuItem value={item.value}>
                                              {displayLabelParts[0]}<br/>{`(${displayLabelParts[1]}`}
                                            </MenuItem>
                                          );
                                        }
                                        return (
                                          <MenuItem value={item.value}>
                                            {item.label}
                                          </MenuItem>
                                        );
                                      })
                                    }
                                  </Select>
                                );
                              } else if (columnInfo.fieldType === 'date') {
                                // date
                                return (
                                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                      fullWidth
                                      inputVariant="outlined"
                                      id="date-picker-dialog"
                                      value={editColumnValue || ''}
                                      format="yyyy-MM-dd"
                                      onChange={(cValue) => { setEditColumnValue(cValue); }}
                                      KeyboardButtonProps={{ 'aria-label': 'change date' }}
                                    />
                                  </MuiPickersUtilsProvider>
                                );
                              } else {
                                // default text
                                return (
                                  <TextField
                                    fullWidth
                                    value={editColumnValue || ''}
                                    variant='outlined'
                                    multiline={columnInfo.fieldType === 'multiline'}
                                    onChange={(event) => {
                                      setEditColumnValue(event.target.value);
                                    }}
                                  />
                                );
                              }
                            };
                            return (
                              <TableCell align="left" padding="none">
                                <div>
                                  {renderColumnInput()}
                                </div>
                                <IconButton onClick={onEditSaveColumnClick(row, props.formInfos[dataFormIndex], columnKey)}>
                                  <CheckCircleIcon style={{ fontSize: '16px', color:'#888888' }}/>
                                </IconButton>
                                <IconButton onClick={onEditColumnClick(null, -1)}>
                                  <CancelIcon style={{ fontSize: '16px', color:'#888888' }}/>
                                </IconButton>
                              </TableCell>
                            );
                          }
                          return (
                            <TableCell align="left" padding="none">
                              {row[headCell.id]}
                              {(index === rowIndex) && <IconButton onClick={onEditColumnClick(row[headCell.id], cIndex)}>
                                <EditIcon style={{ fontSize: '16px', color:'#888888' }}/>
                              </IconButton>}
                            </TableCell>
                          );
                        })
                      }
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[100]}
          component="div"
          count={focusRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      {/* Snack message bar */}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={!!snackMsg}
        autoHideDuration={3000}
        onClose={() => {
          setSnackMsg('');
        }}
        message={snackMsg}
      />
      {/* Send Emamil Dialog */}
      <EmailDialog
        open={openEmailDialog}
        onClose={onCloseEmailDialog}
        orgMember={props.orgMember}
        ownerEmail={chooseForm.owner_email}
        onSubmitEmail={onSubmitEmail}
        form={chooseForm}
      />
      {/* Loading cutscene */}
      { props.loading && (
        <div style={{zIndex:'10',position:'fixed', top:'0', left: '0', backgroundColor:'#D3D3D3', opacity:'0.85', height:'100vh', width:'100vw', display:'flex', justifyContent:'center', alignItems:'center'}}>
          <div style={{  textAlign:'center'}}>
            <img
                  src={Loader}
                  style={{ width: '200px' }}
                  alt='Loading...'
                />
            <h3 style={{color:'white'}}>Loading...</h3>
          </div>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  const {
    auth,
    dealList,
    loading,
    notifyRule,
    // filterStr,
    account,
    orgMember,
    // statusFilter,
    // updateRangeFilter,
    // productTypeFilter,
    // progressFilter,
    // estimatedClosingFilter,
    order,
    orderBy,
    // filterOwner,
    // greedyFilterStr,
    sheetDataForm,
    // filterDataForm,
    formInfos,
    filterInfo,
    screen
  } = state;
  return {
    auth, dealList, loading, notifyRule, account, orgMember, order, orderBy, sheetDataForm, formInfos, filterInfo, screen
  };
};

export default connect(mapStateToProps, {
  setAccountInfo,
  setOrgMember,
  // setStatusFilter,
  setDealList,
  setLoading,
  // setFilterStr,
  // setUpdateRangeFilter,
  // setProductTypeFilter,
  // setProgressFilter,
  // setEstimatedClosingFilter,
  setOrder,
  setOrderBy,
  // setFilterOwner,
  // setGreedyFilterStr,
  // setFilterDataForm,
  setFilterInfo
})(withAuthenticationRequired(injectIntl(DealList)));
