import { CONSTANTS } from "./constants";

const initialFilterStr = '';

export default (state = initialFilterStr, action) => {
  switch (action.type) {
    case CONSTANTS.SET_FILTER_STR :
      return action.value;
    default:
      return state;
  }
}
