import XLSX from 'xlsx';
import detailMap from '../../../configuration/attMap.json';
import { identName } from '../../../lib';

let exportMap = [];

const EXPORT_EXCLUDE_KEY = [
  { type: 'sys', key: 'case_uid'},
  { type: 'owner', key: 'uid'},
  { type: 'sys', key: 'organization'}
];
const DR_EXPORT_EXCLUDE_STATUS = ['Waiting', 'Open', 'Reject'];

const DEFAULT_CSS_STYLE = `<style type="text/css">
table {
  border-collapse: collapse;
  border:1px solid black;
}
th {
  border:1px solid black;
}
td {
  border:1px solid black;
}
</style>`;

const initExportMap = () => {
  const aMap = [];
  const bMap = [];
  detailMap.forEach((map) => {
    if (map.display) {
      if (EXPORT_EXCLUDE_KEY.some((sek) => sek.key === map.key && sek.type === map.type)) {
        return;
      }
      if (map.allowEditStage.length > 0) {
        aMap.push(map);
      } else {
        bMap.push(map);
      }
    }
  });
  return JSON.parse(JSON.stringify(aMap.concat(bMap)));
};

const xlsxRendor_ws = (intl, dealList) => {
  const colsNum = exportMap.length;
  const header = [...new Array(colsNum + 2)].map((a, index) => {
    return identName(index);
  });

  // attribute
  const attrJson = {};
  exportMap.forEach((map, index) => {
    attrJson[header[index]] = intl.formatMessage({ id: map.title_id });
  });
  attrJson[header[exportMap.length]] = 'owner';
  attrJson[header[exportMap.length + 1]] = 'owner email';

  // body
  const bodyArray = dealList.map((dealInfo) => {
    const json = {};
    exportMap.forEach((map, index) => {
      const exportColumnValue = dealInfo[`${map.type}_${map.key}`];
      if (Array.isArray(exportColumnValue)) {
        json[header[index]] = exportColumnValue.join(', ');
      } else {
        json[header[index]] = exportColumnValue;
      }
    });
    json[header[exportMap.length]] = dealInfo.owner_name;
    json[header[exportMap.length + 1]] = dealInfo.owner_email;
    return json;
  });

  const ws = XLSX.utils.json_to_sheet([attrJson].concat(bodyArray), { header, skipHeader: true });
  return ws;
};

const xlsxDownload_ws = (ws, fileName, sheetName) => {
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, ws, sheetName);
  XLSX.writeFile(workbook, fileName);
};

const ws_to_csv = (ws) => {
  return XLSX.utils.sheet_to_csv(ws);
};

const ws_to_txt = (ws) => {
  return XLSX.utils.sheet_to_txt(ws);
};

const ws_to_html = (ws) => {
  return XLSX.utils.sheet_to_html(ws);
};

const injectCSS = (html, cssStyle = DEFAULT_CSS_STYLE) => {
  return html.replace('</head>', `${cssStyle}</head>`);
};

exportMap = initExportMap();

export {
  xlsxRendor_ws,
  xlsxDownload_ws,
  ws_to_csv,
  ws_to_txt,
  ws_to_html,
  injectCSS
};
