import { CONSTANTS } from "./constants";

const initialFilterOwner = [];

export default (state = initialFilterOwner, action) => {
  switch (action.type) {
    case CONSTANTS.SET_FILTER_OWNER :
      return action.value;
    default:
      return state;
  }
}
