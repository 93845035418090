import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { confirmAlert } from 'react-confirm-alert';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';

import EnhancedTableToolbar from './toolBar';
import EnhancedTableHead from './tableHead';
import EditDialog from './editDialog';
import Loader from '../../image/Loading.gif';
import { setLoading, setOrgMember } from '../../actions'
import { httpRequest } from '../../lib';

const createData = (edit, name, email, phone, compony, organization, role, language) => {

  return { edit, name, email, phone, compony, organization, role, language };
};

// const rows = [
//   { user_uid: 0, name: 'myname0', email: 'email0', phone: 'phone0', compony: 'compony0', organization: 'organization0', role: 'role0', language: 'language0' },
//   { user_uid: 1, name: 'myname1', email: 'email1', phone: 'phone1', compony: 'compony1', organization: 'organization1', role: 'role1', language: 'language1' },
//   { user_uid: 2, name: 'myname2', email: 'email2', phone: 'phone2', compony: 'compony2', organization: 'organization2', role: 'role2', language: 'language2' },
//   { user_uid: 3, name: 'myname3', email: 'email3', phone: 'phone3', compony: 'compony3', organization: 'organization3', role: 'role3', language: 'language3' },
//   { user_uid: 4, name: 'myname4', email: 'email4', phone: 'phone4', compony: 'compony4', organization: 'organization4', role: 'role4', language: 'language4' },
//   { user_uid: 5, name: 'myname5', email: 'email5', phone: 'phone5', compony: 'compony5', organization: 'organization5', role: 'role5', language: 'language5' },
//   { user_uid: 6, name: 'myname6', email: 'email6', phone: 'phone6', compony: 'compony6', organization: 'organization6', role: 'role6', language: 'language6' },
//   { user_uid: 7, name: 'myname7', email: 'email7', phone: 'phone7', compony: 'compony7', organization: 'organization7', role: 'role7', language: 'language7' },
//   { user_uid: 8, name: 'myname8', email: 'email8', phone: 'phone8', compony: 'compony8', organization: 'organization8', role: 'role8', language: 'language8' },
//   { user_uid: 9, name: 'myname9', email: 'email9', phone: 'phone9', compony: 'compony9', organization: 'organization9', role: 'role9', language: 'language9' }
// ];

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'edit', numeric: false, disablePadding: false, label: 'Edit' },
  { id: 'name', numeric: false, disablePadding: true, label: 'Name' },
  { id: 'email', numeric: true, disablePadding: false, label: 'email' },
  { id: 'phone', numeric: true, disablePadding: false, label: 'phone' },
  { id: 'compony', numeric: true, disablePadding: false, label: 'compony' },
  { id: 'organization', numeric: true, disablePadding: false, label: 'organization' },
  { id: 'role', numeric: true, disablePadding: false, label: 'role' },
  { id: 'language', numeric: true, disablePadding: false, label: 'language' },
];

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '30px'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const UserManamger = (props) => {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [userFilterStr, setUserFilterStr] = React.useState('');
  const [focusOrg, setFocusOrg] = React.useState('');
  const [selectUserIndex, setSelectUserIndex] = React.useState(-1);
  const [orgOption, setOrgOption] = React.useState([]);

  useEffect(() => {
    if (props.account && props.account.role !== 'admin') {
      // redirect in here.
      window.location.href = "/";
    }
    const orgSet = new Set([]);
    props.orgMember.forEach((om) => {
      if (om.organization) {
        orgSet.add(om.organization);
      }
    });
    const orgArray = Array.from(orgSet);
    setOrgOption(orgArray);
  }, [props.orgMember]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterChange = (event) => {
    setUserFilterStr(event.target.value);
  };
  const handleFocusOrg = (event) => {
    setFocusOrg(event.target.value);
  };

  const searchStrFilter = (filterStr, filterOrg) => (rowObj) => {
    const EXCLUDE_KEYS = ['user_uid'];
    const searchStrArray = filterStr.split(' ');
    if (filterOrg && rowObj.organization !== filterOrg) {
      return false;
    }
    return searchStrArray.every((fStr) => {
      return Object.keys(rowObj).some((key) => {
        return !EXCLUDE_KEYS.includes(key) && `${rowObj[key]}`.includes(fStr);
      });
    })
  };

  const handleOpenEditDialog = (user_uid) => (event) => {
    const index = getSelectUserIndex(user_uid);
    setSelectUserIndex(index);
  };

  const handleCloseEditDialog = (event) => {
    setSelectUserIndex(-1);
  };

  const getSelectUserIndex = (user_uid) => {
    return props.orgMember.findIndex((om) => {
      return om.user_uid === user_uid;
    });
  };

  const handleSaveEditDialog = (userInfo) => {
    // TODO: props.orgMember
    const om = [...props.orgMember];
    
    const { user_uid } = om[selectUserIndex];
    const users = [{ user_uid, ...userInfo }];

    props.setLoading(true);
    setSelectUserIndex(-1);

    httpRequest('PATCH', '/api/manager/orgmember', { users }, props.auth.accessToken, (statusCode, body) => {
      // TODO: add response
      props.setLoading(false);
      if (body.status) {
        om[selectUserIndex] = users[0];
        props.setOrgMember(om);
      }
      confirmAlert({
        title: body.status ? 'Success' : 'Warning',
        message: body.status ? 'Success' : body.message,
        buttons: [{ label: 'Yes' }],
        closeOnClickOutside: false
      });
    });

  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, props.orgMember.length - page * rowsPerPage);
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          onFilterChange={handleFilterChange}
          onFilterValue={userFilterStr}
          focusOrg={focusOrg}
          onFocusOrgChange={handleFocusOrg}
          focusOrgOption={orgOption}
        />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size='small'
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headCells={headCells}
            />
            <TableBody>
              {stableSort(props.orgMember, getComparator(order, orderBy))
                .filter(searchStrFilter(userFilterStr, focusOrg))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow hover key={row.name}>
                      <TableCell padding="checkbox">
                        <IconButton disabled={row.role === 'admin'} aria-label={`enhanced-table-edit-button-${index}`} onClick={handleOpenEditDialog(row.user_uid)}>
                          <EditIcon style={{color: (row.role === 'admin') ? '#dddddd' : '#ffb549'}}/>
                        </IconButton>
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row" padding="none">
                        {row.name}
                      </TableCell>
                      <TableCell align="right">{row.email}</TableCell>
                      <TableCell align="right">{row.phone}</TableCell>
                      <TableCell align="right">{row.compony}</TableCell>
                      <TableCell align="right">{row.organization}</TableCell>
                      <TableCell align="right">{row.role}</TableCell>
                      <TableCell align="right">{row.language}</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 33 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25]}
          component="div"
          labelRowsPerPage={props.intl.formatMessage({ id: 'rows_per_page'})}
          count={props.orgMember.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      <EditDialog
        open={selectUserIndex !== -1}
        userInfo={(selectUserIndex !== -1) ? props.orgMember[selectUserIndex] : {}}
        onClose={handleCloseEditDialog}
        onSave={handleSaveEditDialog}
      />
      { props.loading && (
        <div style={{zIndex:'10',position:'fixed', top:'0', left: '0', backgroundColor:'#D3D3D3', opacity:'0.85', height:'100vh', width:'100vw', display:'flex', justifyContent:'center', alignItems:'center'}}>
          <div style={{  textAlign:'center'}}>
            <img
                  src={Loader}
                  style={{ width: '200px' }}
                  alt='Loading...'
                />
            <h3 style={{color:'white'}}>Loading...</h3>
          </div>
        </div>
      )}
    </div>
  );
};

// reduce => orgMember
const mapStateToProps = state => {
  const { auth, orgMember, loading, account } = state;
  return { auth, orgMember, loading, account };
};

export default connect(mapStateToProps, { setOrgMember, setLoading })(withAuthenticationRequired(injectIntl(UserManamger)));
