import React, { forwardRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import DateFnsUtils from "@date-io/date-fns";
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import ClearIcon from '@material-ui/icons/Clear';
import ArrowLeftOutlinedIcon from '@material-ui/icons/ArrowLeftOutlined';
import ArrowRightOutlinedIcon from '@material-ui/icons/ArrowRightOutlined';
import { HorizontalBar } from 'react-chartjs-2';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { setAuthInfo, setDealList, setLoading, setAccountInfo, setNotifyRule, setOrgMember } from '../../actions'
import { filterEngine } from '../../components/FilterComponents/filterLib';

const CHART_CONSTANTS = {
  BACKGROUND_COLOR: [
    'rgba(255, 99, 132, 0.2)',
    'rgba(255, 159, 64, 0.2)',
    'rgba(255, 205, 86, 0.2)',
    'rgba(75, 192, 192, 0.2)',
    'rgba(54, 162, 235, 0.2)',
    'rgba(153, 102, 255, 0.2)',
    'rgba(201, 203, 207, 0.2)'
  ],
  BORDER_COLOR: [
    'rgb(255, 99, 132)',
    'rgb(255, 159, 64)',
    'rgb(255, 205, 86)',
    'rgb(75, 192, 192)',
    'rgb(54, 162, 235)',
    'rgb(153, 102, 255)',
    'rgb(201, 203, 207)'
  ]
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 350,
  },
  shineRow: {
    '&:hover': {
      backgroundColor: '#DDDDDD !important'
    },
    background: '#3dabe248',
  },
  warnRow: {
    '&:hover': {
      backgroundColor: '#DDDDDD !important'
    },
    background: '#FFD0D0'
  },
  calmRow: {
    '&:hover': {
      backgroundColor: '#DDDDDD !important'
    }
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  searchInput: {
    marginRight: theme.spacing(1)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  }
}));

const groupByKey = (array, key) => {
  return array.reduce((result, currentValue) => {
    (result[currentValue[key]] = result[currentValue[key]] || []).push(
      currentValue
    );
    return result;
  }, {});
};

const Analyz = (props) => {
  const classes = useStyles();
  const [filterOptions, setFilterOptions] = React.useState({
    statusFilterValue: ['Approved']
  });
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [analyzMonth, setAnalyzMonth] = React.useState(null);

  const [orgOption, setOrgOption] = React.useState([]);

  useEffect(() => {
    const orgSet = new Set([]);
    props.orgMember.forEach((om) => {
      if (om.organization) {
        orgSet.add(om.organization);
      }
    });
    const orgArray = Array.from(orgSet);
    setOrgOption(orgArray);
  }, [props.orgMember.length]);

  const onFilterChange = (filterValue) => {
    setFilterOptions(filterValue);
  };

  const filterMod = 'accordion'; // accordion/flatten
  const focusDeals = filterEngine(props.dealList, filterOptions);
  const groupByDeals = groupByKey(focusDeals, 'owner_uid');
  const users = Object.keys(groupByDeals);

  const userChartData = {
    labels: users.map((user) => {
      return groupByDeals[user][0].owner_name;
    }),
    datasets: [
      {
        label: 'Dataset 1', // 月份名稱
        data: users.map((user) => {
          return groupByDeals[user].length;
        }),
        borderColor: CHART_CONSTANTS.BORDER_COLOR[0], // 統一顏色
        backgroundColor: CHART_CONSTANTS.BACKGROUND_COLOR[0]
      }
    ]
  };

  const orgChartData = {
    labels: [...orgOption, 'Unorganized'],
    datasets: [
      {
        label: 'Dataset 2', // 月份名稱
        data: orgOption.map((orgName) => {
          const counter = focusDeals.filter((fd) => {
            return fd.sys_organization === orgName;
          }).length;
          return counter;
        }).concat([focusDeals.filter((fd) => {
          return !fd.sys_organization;
        }).length]),
        borderColor: CHART_CONSTANTS.BORDER_COLOR[0], // 統一顏色
        backgroundColor: CHART_CONSTANTS.BACKGROUND_COLOR[0]
      }
    ]
  };

  const onAnalyzMonthChange =(cValue) => {
    if (!cValue) {
      setAnalyzMonth(null);
      setFilterOptions({
        caseApprovalDateFilterLeftValue: null,
        caseApprovalDateFilterRightValue: null,
        statusFilterValue: ['Approved']
      });
      return;
    }
    const pickDate = new Date(cValue);
    const firstDay = new Date(pickDate.getFullYear(), pickDate.getMonth(), 1);
    const lastDay = new Date(pickDate.getFullYear(), pickDate.getMonth() + 1, 0);
    setAnalyzMonth(cValue);
    setFilterOptions({
      caseApprovalDateFilterLeftValue: firstDay,
      caseApprovalDateFilterRightValue: lastDay,
      statusFilterValue: ['Approved']
    });
  };
  

  return (
    <div className={classes.root}>
      <Backdrop className={classes.backdrop} open={openBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* <Toolbar>
        <FilterComponents
          mode={filterMod}
          onFilterChange={onFilterChange}
          enableStrictFilter={true}
          enableGreedyFilter={true}
          enableStatusFilter={true}
          enableUpdateRangeFilter={true}
          enableProductTypeFilter={true}
          enableProgressFilter={true}
          enableCreateStampFilter={false}
          enableCaseApprovalDateFilter={true}
          enableEstimatedClosingFilter={true}
          enableOwnerFilter={true}
          enableDataFormFilter={false}
          ownerList={props.orgMember.map((orgM) => {
            return { label: orgM.name, value: orgM.name };
          })}
          dataFormList={[]}
        />
      </Toolbar> */}
      <Paper className={classes.paper}>
        <Toolbar className={classes.root} >
          <Typography className={classes.title} style={{fontSize:'30px'}} variant="h6" id="tableTitle" component="div">
            Analyz
          </Typography>
        </Toolbar>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormattedMessage id="pick_month" />
            
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                id="left-date-picker-dialog"
                style={{ top: '15px' }}
                value={analyzMonth || null}
                format="yyyy-MM"
                openTo='month'
                views={["year", "month"]}
                onChange={onAnalyzMonthChange}
                KeyboardButtonProps={{ 'aria-label': 'change date' }}
                InputProps={{
                  startAdornment: (
                    <IconButton size='small' style={{order: 1}} onClick={(e) => {
                      onAnalyzMonthChange(null);
                    }}>
                      <ClearIcon />
                    </IconButton>
                  )
                }}
                InputAdornmentProps={{
                  position: "end", style: {order: 2, marginLeft: 0}
                }}
              />
            </MuiPickersUtilsProvider>
            <Tooltip title={props.intl.formatMessage({ id: 'last_month' })}>
              <IconButton color='inherit' onClick={(() => {
                const lastMonth = new Date(analyzMonth || new Date());
                lastMonth.setMonth(lastMonth.getMonth() - 1);
                onAnalyzMonthChange(lastMonth);
              })}>
                <ArrowLeftOutlinedIcon/>
              </IconButton>
            </Tooltip>

            <Tooltip title={props.intl.formatMessage({ id: 'next_month' })}>
              <IconButton color='inherit' onClick={() => {
                const nextMonth = new Date(analyzMonth || new Date());
                nextMonth.setMonth(nextMonth.getMonth() + 1);
                onAnalyzMonthChange(nextMonth);
              }}>
                <ArrowRightOutlinedIcon/>
              </IconButton>
            </Tooltip>
            <TableContainer>
              <Table className={classes.table} aria-label="simple table" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: '80%' }} align='center'>
                      <FormattedMessage id='name' style={{ width: '100px' }}/>
                    </TableCell>
                    <TableCell style={{ width: '20%' }} align='center'>
                      <FormattedMessage id='quantity' style={{ width: '100px' }}/>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    users.map((user) => {
                      return (
                        <TableRow>
                          <TableCell align='left' style={{ width: '80%' }}>
                            <Tooltip title={groupByDeals[user][0].owner_email}>
                              <div>{groupByDeals[user][0].owner_name}</div>
                            </Tooltip>
                          </TableCell>
                          <TableCell align='center' style={{ width: '20%' }}>{groupByDeals[user].length}</TableCell>
                        </TableRow>
                      );
                    })
                  }
                  {
                    (focusDeals.length !== 0) && <>
                      <TableRow>
                        <TableCell align='left' style={{ width: '80%', height: '34px' }} />
                        <TableCell align='center' style={{ width: '20%', height: '34px' }} />
                      </TableRow>
                      <TableRow>
                        <TableCell align='left' style={{ width: '80%' }}>
                          <div>{props.intl.formatMessage({ id: 'total' })}</div>
                        </TableCell>
                        <TableCell align='center' style={{ width: '20%' }}>{focusDeals.length}</TableCell>
                      </TableRow>
                    </>
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={6}>
            <HorizontalBar data={userChartData} options={{
              scales: {
                xAxes: [{
                  display: true,
                  ticks: {
                      // suggestedMin: 0,    // minimum will be 0, unless there is a lower value.
                      // OR //
                      beginAtZero: true   // minimum value will be 0.
                  }
                }]
              }
            }}/>
          </Grid>
        </Grid>
        
        {
          props.account.role === 'admin' && <>
          <Divider style={{ margin: '20px 0px' }}/>
          <Toolbar className={classes.root} >
            <Typography className={classes.title} style={{fontSize:'30px'}} variant="h6" id="tableTitle" component="div">
            Organize Analyz
            </Typography>
          </Toolbar>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TableContainer>
                <Table className={classes.table} aria-label="simple table" size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ width: '80%' }} align='center'>
                        <FormattedMessage id='organization' style={{ width: '100px' }}/>
                      </TableCell>
                      <TableCell style={{ width: '20%' }} align='center'>
                        <FormattedMessage id='quantity' style={{ width: '100px' }}/>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      orgOption.map((orgName) => {
                        const counter = focusDeals.filter((deal) => {
                          return deal.sys_organization === orgName;
                        }).length;
                        return (
                          <TableRow>
                            <TableCell align='left' style={{ width: '80%' }}>
                              <Tooltip title={orgName}>
                                <div>{orgName}</div>
                              </Tooltip>
                            </TableCell>
                            <TableCell align='center' style={{ width: '20%' }}>{counter}</TableCell>
                          </TableRow>
                        );
                      })
                    }
                    <TableRow>
                      <TableCell align='left' style={{ width: '80%' }}>
                        <Tooltip title={'Unorganized'}>
                          <div>{'Unorganized'}</div>
                        </Tooltip>
                      </TableCell>
                      <TableCell align='center' style={{ width: '20%' }}>{
                        focusDeals.filter((deal) => {
                          return !deal.sys_organization;
                        }).length
                      }</TableCell>
                    </TableRow>
                    {
                      (focusDeals.length !== 0) && <>
                        <TableRow>
                          <TableCell align='left' style={{ width: '80%', height: '34px' }} />
                          <TableCell align='center' style={{ width: '20%', height: '34px' }} />
                        </TableRow>
                        <TableRow>
                          <TableCell align='left' style={{ width: '80%' }}>
                            <div>{props.intl.formatMessage({ id: 'total' })}</div>
                          </TableCell>
                          <TableCell align='center' style={{ width: '20%' }}>{focusDeals.length}</TableCell>
                        </TableRow>
                      </>
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={6}>
            <HorizontalBar data={orgChartData} options={{
              scales: {
                xAxes: [{
                  display: true,
                  ticks: {
                      // suggestedMin: 0,    // minimum will be 0, unless there is a lower value.
                      // OR //
                      beginAtZero: true   // minimum value will be 0.
                  }
                }]
              }
            }}/>
          </Grid>
          </Grid>
          </>
        }
      </Paper>
    </div>
  );
};

const mapStateToProps = state => {
  const { dealList, loading, orgMember, account } = state;
  return { dealList, loading, orgMember, account };
};

export default connect(mapStateToProps, {})(withAuthenticationRequired(injectIntl(Analyz)));
