import { CONSTANTS } from "./constants";

const initialStatusFilter = [];

export default (state = initialStatusFilter, action) => {
  switch (action.type) {
    case CONSTANTS.SET_PROGRESS_FILTER :
      return action.value;
    default:
      return state;
  }
}
